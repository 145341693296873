import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";

const Login = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const login = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.login,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setUserInfo({
            aToken: res.auth_info.access_token,
            isLogin: true,
            user_id: res.user_id,
            user_name: res.user_name,
            user_type: res.user_type,
          });
          localStorage.setItem("rToken", res.auth_info.refresh_token);
          navigate("/");
        } else {
          setUserInfo({
            aToken: null,
            isLogin: false,
            user_id: null,
            user_name: null,
            user_type: null,
          });
          localStorage.removeItem("rToken");
          alert(res.message);
        }
      })
      .catch((err) => {
        //console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    if (userInfo.userInfo != null) {
      navigate("/");
    }
  }, []);

  return (
    <div className="container login">
      <div className="member-section">
        <div className="member-area">
          <div className="title-area">
            <h2>LOGIN</h2>
            <p>당신의 선택이 더 가치있도록 노력하는 클리니션이 되겠습니다.</p>
          </div>
          <form onSubmit={handleSubmit(login)}>
            <div className="input-area">
              <div className="input-style">
                <label for="login_id">아이디</label>
                <InputUnit
                  label="login_id"
                  type="text"
                  holder="아이디"
                  register={register("login_id", {
                    required: "아이디는 필수 입력 값 입니다.",
                    minLength: {
                      value: 3,
                      message: "최소 3자 이상 입력 해 주세요",
                    },
                    maxLength: {
                      value: 22,
                      message: "최대 22자 이하로 입력 해 주세요",
                    },
                    pattern: {
                      value: /^[a-z0-9]+[a-z0-9]+$/i,
                      message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
                    },
                  })}
                  errors={formErrors}
                />
              </div>
              <div className="input-style">
                <label for="login_pw">비밀번호</label>
                <InputUnit
                  label="login_pw"
                  type="password"
                  holder="비밀번호"
                  register={register("login_pw", {
                    required: "패스워드는 필수 입력 값 입니다.",
                  })}
                  errors={formErrors}
                />
              </div>
            </div>
            <div className="button-area">
              <button
                type="submit"
                className="button-primary size-l"
                disabled={isSubmitting}
              >
                <span>Sign in</span>
              </button>
            </div>
          </form>
          <div className="extra-area">
            <ul>
              <li>
                <Link to="/reqId">ID 찾기</Link>
              </li>
              <li>
                <Link to="/reqPw">비밀번호찾기</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/signup">회원가입</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
