import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { joinInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { CustomRegExp } from "helpers/customRegExp";
import SearchAddress from "./searchAddress";

const StepThree = (props) => {
  const [loading, setLoading] = useState(false);
  const [isAddress, setIsAddress] = useState(false);
  const [joinInfo, setJoinInfo] = useRecoilState(joinInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors: formErrors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      company_name: joinInfo.company_name,
      company_number: joinInfo.company_number,
      company_post_number: joinInfo.company_post_number,
      company_adress1: joinInfo.company_adress1,
      company_adress2: joinInfo.company_adress2,
      company_tel: joinInfo.company_tel,
      company_manager_name: joinInfo.company_manager_name,
      company_manager_tel: joinInfo.company_manager_tel,
    },
  });

  const searchAddr = () => {
    setIsAddress(!isAddress);
  };

  const handleCompanyName = (event) => {
    setJoinInfo({
      ...joinInfo,
      company_name: event.currentTarget.value,
    });
  };

  const handleCompanyNumber = (event) => {
    setJoinInfo({
      ...joinInfo,
      company_number: event.currentTarget.value,
    });
  };

  const handleCompanyAddress2 = (event) => {
    setJoinInfo({
      ...joinInfo,
      company_adress2: event.currentTarget.value,
    });
  };

  const handleCompanyTel = (event) => {
    setJoinInfo({
      ...joinInfo,
      company_tel: event.currentTarget.value,
    });
  };

  const handleCompanyManagerName = (event) => {
    setJoinInfo({
      ...joinInfo,
      company_manager_name: event.currentTarget.value,
    });
  };

  const handleCompanyManagerTel = (event) => {
    setJoinInfo({
      ...joinInfo,
      company_manager_tel: event.currentTarget.value,
    });
  };

  const handleCompanyPostNumberAddr = (data) => {
    setValue("company_post_number", data.company_post_number);
    setValue("company_adress1", data.company_adress1);
    setJoinInfo({
      ...joinInfo,
      company_post_number: data.company_post_number,
      company_adress1: data.company_adress1,
    });
  };

  const joinpwd = useRef();
  joinpwd.current = watch("join_pw");

  const setIndex = props.setStepIndex;
  const preStep = () => {
    setIndex(1);
  };

  const signup = (reqData) => {
    setLoading(true);

    axiosFetch({
      method: "post",
      url: apiUrls.signup,
      requestConfig: joinInfo,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          navigate("/login");
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});

    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isAddress === false ? (
        <div className="member-step step1">
          <div className="member-area">
            <div className="title-area">
              <h2>사업장정보 입력</h2>
              <p>당신의 선택이 더 가치있도록 노력하는 클리니션이 되겠습니다.</p>
            </div>
            <div className="input-area">
              <div className="input-style type2">
                <label for="company_name">상호</label>
                <InputUnit
                  type="text"
                  id="company_name"
                  holder="상호"
                  register={register("company_name", {
                    required: "상호는 필수 입력 값 입니다.",
                    pattern: CustomRegExp("name"),
                  })}
                  errors={formErrors}
                  onChange={(e) => {
                    handleCompanyName(e);
                  }}
                />
                {/* <p className="tip">
              001부터 임의로 부여하며 본사도 포함되어서 작성하셔야 합니다.
            </p> */}
              </div>
              <div className="input-style type2">
                <label for="company_number">사업자등록번호</label>
                <InputUnit
                  type="text"
                  id="company_number"
                  holder="사업자등록번호"
                  register={register("company_number", {
                    required: "사업자등록번호는 필수 입력 값 입니다.",
                    pattern: CustomRegExp("company"),
                  })}
                  errors={formErrors}
                  onChange={(e) => {
                    handleCompanyNumber(e);
                  }}
                />
              </div>
              <div className="input-style type2">
                <label for="company_adress">주소검색</label>
                <button
                  type="button"
                  className="button-primary size-s"
                  id="btn_dialog"
                  onClick={searchAddr}
                  disabled={loading}
                >
                  <span>주소검색</span>
                </button>
              </div>
              <div className="input-style type2">
                <label for="company_post_number">우편번호</label>
                <InputUnit
                  disabled={true}
                  type="text"
                  id="company_post_number"
                  holder="우편번호"
                  register={register("company_post_number", {
                    required: "",
                  })}
                  errors={formErrors}
                />
              </div>
              <div className="input-style type2">
                <label for="company_adress1">주소</label>
                <InputUnit
                  disabled={true}
                  type="text"
                  id="company_adress1"
                  holder="주소"
                  register={register("company_adress1", {
                    required: "",
                  })}
                  errors={formErrors}
                />
              </div>
              <div className="input-style type2">
                <label for="company_adress2">상세주소</label>
                <InputUnit
                  type="text"
                  id="company_adress2"
                  holder="상세주소"
                  register={register("company_adress2", {
                    required: "주소는 필수 입력 값 입니다.",
                  })}
                  errors={formErrors}
                  onChange={(e) => {
                    handleCompanyAddress2(e);
                  }}
                />
              </div>
              <div className="input-style type2">
                <label for="company_tel">대표번호</label>
                <InputUnit
                  type="text"
                  id="company_tel"
                  holder="대표번호"
                  register={register("company_tel", {
                    required: "대표번호는 필수 입력 값 입니다.",
                    pattern: CustomRegExp("tel"),
                  })}
                  errors={formErrors}
                  onChange={(e) => {
                    handleCompanyTel(e);
                  }}
                />
              </div>
              <div className="twobutton-area">
                <button
                  type="button"
                  className="button-primary size-l"
                  id="btn_dialog"
                  onClick={preStep}
                >
                  <span>이전단계</span>
                </button>
                <button
                  type="button"
                  className="button-primary size-l"
                  id="btn_dialog"
                  onClick={handleSubmit(signup)}
                  disabled={loading}
                >
                  <span>가입신청</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SearchAddress
          searchAddr={searchAddr}
          setAddr={handleCompanyPostNumberAddr}
        />
      )}
    </>
  );
};

export default StepThree;
