import React from "react";
import Select from "react-select";

const FeedbackInfo = (props) => {
  const feedback = props.feedback;

  const selectList = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];

  const selectWork = [
    { value: 1, label: "상당히 감소하였다" },
    { value: 2, label: "감소하였다" },
    { value: 3, label: "기존과 같다" },
    { value: 4, label: "늘어났다" },
  ];

  return (
    <div className="admin-dialog dialog-wrap" id="modal_admin_detail">
      <div className="dialog-box admin-dialog-box survey">
        <div className="admin-dialog-area">
          <div className="title-area">
            <h2>고객 만족도 조사 시트</h2>
          </div>
          <div className="dialog-content">
            <div className="admin-dialog-item">
              <h3 className="adi-title">
                전반적인 클리니션 서비스에 대한 만족도는?
              </h3>
              <div className="adi-content">
                <Select
                  className="admin-select adi-company-category"
                  isDisabled
                  options={selectList}
                  value={selectList.filter(function (option) {
                    return option.value === feedback.service;
                  })}
                ></Select>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">청소 상태에 대한 만족도는?</h3>
              <div className="adi-content">
                <Select
                  className="admin-select adi-company-category"
                  isDisabled
                  options={selectList}
                  value={selectList.filter(function (option) {
                    return option.value === feedback.cleaning;
                  })}
                ></Select>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">직원의 친절함 만족도는?</h3>
              <div className="adi-content">
                <Select
                  className="admin-select adi-company-category"
                  isDisabled
                  options={selectList}
                  value={selectList.filter(function (option) {
                    return option.value === feedback.kind;
                  })}
                ></Select>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">
                운영관리시스템(ZIM)에 대한 만족도는?
              </h3>
              <div className="adi-content">
                <Select
                  className="admin-select adi-company-category"
                  isDisabled
                  options={selectList}
                  value={selectList.filter(function (option) {
                    return option.value === feedback.zim;
                  })}
                ></Select>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">
                클리니션 서비스 진행 이후 청소 관리 업무는?
              </h3>
              <div className="adi-content">
                <Select
                  className="admin-select adi-company-category"
                  isDisabled
                  options={selectWork}
                  value={selectWork.filter(function (option) {
                    return option.value === feedback.work;
                  })}
                ></Select>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">
                최초 계약한 서비스 내용이 잘 진행되고 있다고 생각하나?
              </h3>
              <div className="adi-content">
                <textarea
                  className="textarea"
                  disabled
                  defaultValue={feedback.comment1}
                ></textarea>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">
                클리니션 서비스 받은 이후에 가장 만족스러운 점은?
              </h3>
              <div className="adi-content">
                <textarea
                  className="textarea"
                  disabled
                  defaultValue={feedback.comment2}
                ></textarea>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">기타 의견을 자유롭게 적어주세요.</h3>
              <div className="adi-content">
                <textarea
                  className="textarea"
                  disabled
                  defaultValue={feedback.comment3}
                ></textarea>
              </div>
            </div>
            <div className="btn-area">
              <button type="button" onClick={props.closeModal}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackInfo;
