import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { joinInfoState } from "recoil/userStore";

const StepOne = (props) => {
  const [checkAll, setCheckAll] = useState(false);
  const [condition, setConditions] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [joinInfo, setJoinInfo] = useRecoilState(joinInfoState);

  const checkAllClick = () => {
    const state = !checkAll;
    setCheckAll(state);
    setConditions(state);
    setPersonal(state);
  };

  const checkConditionClick = () => {
    const state = !condition;
    setConditions(state);
    if (state && personal) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const checkPersonalClick = () => {
    const state = !personal;
    setPersonal(state);
    if (state && condition) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const setIndex = props.setStepIndex;
  const nextStep = () => {
    if (checkAll) {
      setIndex(1);
      setJoinInfo({
        ...joinInfo,
        checkAll: true,
        checkCondition: true,
        checkPersion: true,
      });
    } else {
      alert("이용약관 및 이용동의에 모두 동의하셔야 됩니다.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setCheckAll(joinInfo.checkAll);
    setConditions(joinInfo.checkCondition);
    setPersonal(joinInfo.checkPersion);
  }, []);

  return (
    <div className="member-step step2">
      <div className="member-area">
        <div className="title-area">
          <h2>약관 동의</h2>
          <p>당신의 선택이 더 가치있도록 노력하는 클리니션이 되겠습니다.</p>
        </div>
        <div className="term-all agree-area">
          <div className="radio-style">
            <input
              type="checkbox"
              id="join_agree_all"
              checked={checkAll}
              onChange={checkAllClick}
            />
            <label for="join_agree_all">
              모든 약관을 확인하고 전체 동의합니다.
            </label>
          </div>
        </div>
        <section className="term-conditions">
          <div className="agree-area">
            <div className="radio-style">
              <input
                type="checkbox"
                id="join_conditions"
                checked={condition}
                onChange={checkConditionClick}
              />
              <label for="join_conditions">동의함(필수) 이용약관</label>
            </div>
          </div>
          <div className="terms-area">
            제 1 조 (목적)
            <br />
            이 약관은 회원이 클리니션(이하 회사라 합니다)와 제공하는 인터넷 관련
            서비스인 클리니션 (영문명 | www.cleanition.co.kr , 이하 "서비스"라
            합니다)를이용함에 있어 회원과 회사간의 권리, 의무 및 책임사항,
            하나의 ID 및 PASSWORD로 통합하여 이용하는데 따른 이용조건 및 절차 등
            기본적인 사항을 규정함을 목적으로 합니다.
            <br />
            <br />
            제 2 조 (약관의 효력 및 변경)
            <br />
            1. 이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력이
            발생합니다. <br />
            2. 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로
            회원에게 공시하고,이에 동의한 회원이 서비스에 가입함으로써 효력이
            발생합니다.
            <br />
            3. 회사는 필요하다고 인정되는 경우 사전 고지 없이 본 약관을 변경할
            수 있으며,약관이 변경된 3. 회사는 필요하다고 인정되는 경우 사전 고지
            없이 본 약관을 변경할 수 있으며,약관이 변경된 3. 회사는 필요하다고
            인정되는 경우 사전 고지 없이 본 약관을 변경할 수 있으며,약관이
            변경된 3. 회사는 필요하다고 인정되는 경우 사전 고지 없이 본 약관을
            변경할 수 있으며,약관이 변경된 3. 회사는 필요하다고 인정되는 경우
            사전 고지 없이 본 약관을 변경할 수 있으며,약관이 변경된
          </div>
        </section>
        <section className="term-personal-info">
          <div className="agree-area">
            <div className="radio-style">
              <input
                type="checkbox"
                id="join_personal"
                checked={personal}
                onChange={checkPersonalClick}
              />
              <label for="join_personal">
                동의함(필수) 개인정보수집 및 이용동의
              </label>
            </div>
          </div>
          <div className="terms-area">
            제 1 조 (목적)
            <br />
            이 약관은 회원이 클리니션(이하 회사라 합니다)와 제공하는 인터넷 관련
            서비스인 클리니션 (영문명 | www.cleanition.co.kr , 이하 "서비스"라
            합니다)를이용함에 있어 회원과 회사간의 권리, 의무 및 책임사항,
            하나의 ID 및 PASSWORD로 통합하여 이용하는데 따른 이용조건 및 절차 등
            기본적인 사항을 규정함을 목적으로 합니다.
            <br />
            <br />
            제 2 조 (약관의 효력 및 변경)
            <br />
            1. 이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력이
            발생합니다. <br />
            2. 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로
            회원에게 공시하고,이에 동의한 회원이 서비스에 가입함으로써 효력이
            발생합니다.
            <br />
            3. 회사는 필요하다고 인정되는 경우 사전 고지 없이 본 약관을 변경할
            수 있으며,약관이 변경된 3. 회사는 필요하다고 인정되는 경우 사전 고지
            없이 본 약관을 변경할 수 있으며,약관이 변경된3. 회사는 필요하다고
            인정되는 경우 사전 고지 없이 본 약관을 변경할 수 있으며,약관이
            변경된3. 회사는 필요하다고 인정되는 경우 사전 고지 없이 본 약관을
            변경할 수 있으며,약관이 변경된3. 회사는 필요하다고 인정되는 경우
            사전 고지 없이 본 약관을 변경할 수 있으며,약관이 변경된3. 회사는
            필요하다고 인정되는 경우 사전 고지 없이 본 약관을 변경할 수
            있으며,약관이 변경된
          </div>
        </section>
        <div className="button-area">
          <button
            type="button"
            className="button-primary size-l"
            id="btn_dialog"
            onClick={nextStep}
          >
            <span>다음단계</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
