import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { joinInfoState } from "recoil/userStore";
import InputUnit from "components/InputUnit";
import { CustomRegExp } from "helpers/customRegExp";

const StepTwo = (props) => {
  const [joinInfo, setJoinInfo] = useRecoilState(joinInfoState);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      join_id: joinInfo.join_id,
      join_pw: joinInfo.join_pw,
      join_pw_check: joinInfo.join_pw_check,
      join_name: joinInfo.join_name,
      join_email: joinInfo.join_email,
      join_tel: joinInfo.join_tel,
    },
  });

  const handleId = (event) => {
    setJoinInfo({
      ...joinInfo,
      join_id: event.currentTarget.value,
    });
  };

  const handlePw = (event) => {
    setJoinInfo({
      ...joinInfo,
      join_pw: event.currentTarget.value,
    });
  };

  const handlePwCheck = (event) => {
    setJoinInfo({
      ...joinInfo,
      join_pw_check: event.currentTarget.value,
    });
  };

  const handleName = (event) => {
    setJoinInfo({
      ...joinInfo,
      join_name: event.currentTarget.value,
    });
  };

  const handleEmail = (event) => {
    setJoinInfo({
      ...joinInfo,
      join_email: event.currentTarget.value,
    });
  };

  const handleTel = (event) => {
    setJoinInfo({
      ...joinInfo,
      join_tel: event.currentTarget.value,
    });
  };

  const joinpwd = useRef();
  joinpwd.current = watch("join_pw");

  const setIndex = props.setStepIndex;
  const preStep = () => {
    setIndex(0);
  };
  const nextStep = () => {
    setIndex(2);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="member-step step1">
      <div className="member-area">
        <div className="title-area">
          <h2>개인정보 입력</h2>
          <p>당신의 선택이 더 가치있도록 노력하는 클리니션이 되겠습니다.</p>
        </div>
        <div className="input-area">
          <div className="input-style type2">
            <label for="join_id">아이디</label>
            <InputUnit
              type="text"
              id="join_id"
              holder="아이디"
              register={register("join_id", {
                required: "아이디는 필수 입력 값 입니다.",
                minLength: {
                  value: 3,
                  message: "최소 3자 이상 입력 해 주세요",
                },
                maxLength: {
                  value: 22,
                  message: "최대 22자 이하로 입력 해 주세요",
                },
                pattern: CustomRegExp("id"),
              })}
              errors={formErrors}
              onChange={(e) => {
                handleId(e);
              }}
            />
          </div>
          <div className="input-style type2">
            <label for="join_pw">비밀번호</label>
            <InputUnit
              type="password"
              id="join_pw"
              holder="비밀번호"
              register={register("join_pw", {
                required: "패스워드는 필수 입력 값 입니다.",
                minLength: {
                  value: 8,
                  message: "최소 8자 이상 입력 해 주세요",
                },
                maxLength: {
                  value: 16,
                  message: "최대 16자 이하로 입력 해 주세요",
                },
              })}
              errors={formErrors}
              onChange={(e) => {
                handlePw(e);
              }}
            />
            <p className="tip">
              (영문 대소문자 / 숫자 / 특수문자 중 2가지 이상 조합, 8자~16자)
            </p>
          </div>
          <div className="input-style type2">
            <label for="join_pw_check">비밀번호 확인</label>
            <InputUnit
              type="password"
              id="join_pw_check"
              holder="비밀번호 확인"
              register={register("join_pw_check", {
                required: "",
                validate: (value) => value === joinpwd.current,
              })}
              errors={formErrors}
              onChange={(e) => {
                handlePwCheck(e);
              }}
            />
          </div>
          <div className="input-style type2">
            <label for="join_name">이름</label>
            <InputUnit
              type="text"
              id="join_name"
              holder="이름"
              register={register("join_name", {
                required: "이름은 필수 입력 값 입니다.",
                pattern: CustomRegExp("name"),
              })}
              errors={formErrors}
              onChange={(e) => {
                handleName(e);
              }}
            />
          </div>
          <div className="input-style type2">
            <label for="join_email">이메일</label>
            <InputUnit
              type="text"
              id="join_email"
              holder="이메일"
              register={register("join_email", {
                required: "이메일은 필수 입력 값 입니다.",
                pattern: CustomRegExp("email"),
              })}
              errors={formErrors}
              onChange={(e) => {
                handleEmail(e);
              }}
            />
          </div>
          <div className="input-style type2">
            <label for="join_tel">전화번호</label>
            <InputUnit
              type="text"
              id="join_tel"
              holder="전화번호"
              register={register("join_tel", {
                required: "전화번호는 필수 입력 값 입니다.",
                pattern: CustomRegExp("tel"),
              })}
              errors={formErrors}
              onChange={(e) => {
                handleTel(e);
              }}
            />
          </div>
        </div>
        <div className="twobutton-area">
          <button
            type="button"
            className="button-primary size-l"
            id="btn_dialog"
            onClick={preStep}
          >
            <span>이전단계</span>
          </button>
          <button
            type="button"
            className="button-primary size-l"
            id="btn_dialog"
            onClick={handleSubmit(nextStep)}
          >
            <span>다음단계</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
