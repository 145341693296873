import React from "react";

const IrregularClinicPage = () => {
  return (
    <div className="container">
      <section className="visual-top cleaning2">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>클리니션의 표준화된 서비스 프로세스를 직접 경험 해보세요</p>
            <h2>비정기클리닝 </h2>
          </div>
        </div>
      </section>
      <section className="cleaning-section-needs">
        <div className="inner wide">
          <div className="text-area">
            <h2>비정기클리닝</h2>
            <div className="ment">
              클리니션의 정기 클리닝 서비스는 지속적인 서비스 품질 강화를
              진행합니다. <br />
              서비스 운영 중에도 고객과의 커뮤니케이션으로 최적화 서비스를 위한
              활동을 제공합니다.
            </div>
          </div>
          <div className="img-box type2">
            <div className="img-box-left">
              <img src="../assets/images/cleaning/person_03.png" alt="" />
              <span>****비바체 박사원의 고민</span>
              <p>
                모든 업체가 얼마인지만 얘기하고 가는데… 어떤 프로세스로 어떻게
                작업이 진행되는지 알 수가 없네?? 미리 내용 전달을 해주는 게
                당연한거 아닌가??
              </p>
            </div>
            <div className="img-box-right">
              <img src="../assets/images/cleaning/person_04.png" alt="" />
              <span>**디자인 김대리님의 고민</span>
              <p>
                현장에서는 작업 진행을 하고 있는데 최초 계획에 맞춰서 진행되고
                있을까? 완료된 현장은 둘러보는 건 잠깐인데, 어떻게 완료 되었는지
                너무 답답하네..
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="cleaning-section-step">
        <div className="inner">
          <div className="text-area">
            <h2>클리니션이 프로세스의 가치를 새롭게 정의 내려 드립니다.</h2>
            <div className="ment">
              클리니션의 모든 서비스는 표준으로 정의된 프로세스부터 시작합니다.
              <br />
              차별화된 품질의 서비스를 받아보세요.
            </div>
          </div>
          <div className="cleaning-process-list">
            <ul>
              <li className="process1">
                <div className="process-img-box">
                  <img src="../assets/images/cleaning/process01.jpg" alt="" />
                </div>
                <div className="process-text-box">
                  <div>사전작업계획서</div>
                  <p>
                    깜깜했던 서비스 진행은 이제 그만~ <br />
                    서비스 진행 이전에 자세한 작업 계획서를 받아보세요. 최고의
                    서비스는 완벽한 프로세스 안에서 시작합니다.
                  </p>
                </div>
              </li>
              <li className="process2">
                <div className="process-img-box">
                  <img src="../assets/images/cleaning/process02.jpg" alt="" />
                </div>
                <div className="process-text-box">
                  <div>작업진행</div>
                  <p>
                    각 분야 최고의 전문가들이 직접 서비스를 진행합니다.
                    <br />
                    현장 환경에 맞춰 최적의 기기와 약품을 선택하여 제공합니다.
                    <br />
                    효율적인 시간 관리로 서비스의 품질은 물론 시간 절약까지
                    두마리 토끼를 한번에 잡습니다!
                  </p>
                </div>
              </li>
              <li className="process3">
                <div className="process-img-box">
                  <img src="../assets/images/cleaning/process03.jpg" alt="" />
                </div>
                <div className="process-text-box">
                  <div>사후 완료보고</div>
                  <p>
                    이젠 먼지나고 위험한 현장 확인을 최소한의 시간만 투자
                    하십시오. 작업의 마무리는 완벽한 사후 보고로 받으시면
                    됩니다.
                    <br />
                    고객의 시간은 클리니션이 지켜냅니다.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="cleaning-section-detail">
        <div className="inner">
          <div className="text-area">
            <h2>서비스 상세</h2>
          </div>
          <div className="cleaning-detail-list">
            <ul>
              <li>
                <i>
                  <img
                    src="../assets/images/cleaning/icon_cleaning01.png"
                    alt=""
                  />
                </i>
                <div>준공청소</div>
              </li>
              <li>
                <i>
                  <img
                    src="../assets/images/cleaning/icon_cleaning02.png"
                    alt=""
                  />
                </i>
                <div>대청소</div>
              </li>
              <li>
                <i>
                  <img
                    src="../assets/images/cleaning/icon_cleaning03.png"
                    alt=""
                  />
                </i>
                <div>바닥왁스코팅</div>
              </li>
              <li>
                <i>
                  <img
                    src="../assets/images/cleaning/icon_cleaning04.png"
                    alt=""
                  />
                </i>
                <div>건물외벽청소</div>
              </li>
              <li>
                <i>
                  <img
                    src="../assets/images/cleaning/icon_cleaning05.png"
                    alt=""
                  />
                </i>
                <div>에어컨청소</div>
              </li>
              <li>
                <i>
                  <img
                    src="../assets/images/cleaning/icon_cleaning06.png"
                    alt=""
                  />
                </i>
                <div>카페트청소</div>
              </li>
              <li>
                <i>
                  <img
                    src="../assets/images/cleaning/icon_cleaning07.png"
                    alt=""
                  />
                </i>
                <div>쇼파청소</div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IrregularClinicPage;
