import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { subcontractListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import CustomerList from "components/popup/customerList";
import moment from "moment";

const SubcontractListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [userList, setUserList] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  const [userListPage, setUserListPage] = useRecoilState(
    subcontractListPageIndexState
  );

  const selectOptions = [
    { value: "U", label: "일반" },
    { value: "C", label: "고객사" },
    { value: "P", label: "협력사" },
    { value: "A", label: "관리자" },
  ];

  const selectOptions1 = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
  ];

  const openModal = (e, item) => {
    e.preventDefault();
    setSelectedUser(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const searchDataChange = (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
  };

  const setSelectedValue = (e, item) => {
    item.user_type = e.value;
  };

  const setSelectedReliabilityValue = (e, item) => {
    item.reliability = e.value;
  };

  const setCleaningAreaValue = (e, item) => {
    item.cleaning_area = e.target.value;
  };

  const getUserListPage = (e, page) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.getUserList,
      requestConfig: {
        currentNavPage: page,
        searchData: searchData,
        user_type: "P",
      },
    })
      .then((res) => {
        if (res.success) {
          setUserResult(res.result);
          setUserList(res.userList);
          setPageList(res.pageList);
          setUserListPage(page);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getUserList : ", err);
      });
  };

  const getUserList = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getUserList,
      requestConfig: {
        currentNavPage: userListPage,
        searchData: searchData,
        user_type: "P",
      },
    })
      .then((res) => {
        if (res.success) {
          setUserResult(res.result);
          setUserList(res.userList);
          setPageList(res.pageList);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getUserList : ", err);
      });
  };

  const updateUserList = (e, reqData) => {
    e.preventDefault();

    axiosFetchA({
      method: "post",
      url: apiUrls.updateUserList,
      requestConfig: { reqData },
    })
      .then((res) => {
        if (res.success) {
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] updateUserList : ", err);
      });
  };

  const formHandleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <div className="board-list-content">
        <div className="board-head">
          <div className="board-search">
            <form onSubmit={formHandleSubmit}>
              <fieldset className="">
                <label className="search-keyword">
                  <input
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    onChange={searchDataChange}
                    value={searchData}
                  />
                </label>
                <button
                  id="btn-search"
                  type="button"
                  className="btn btn-search"
                  onClick={(e) => {
                    getUserListPage(e, 1);
                  }}
                >
                  검색
                </button>
              </fieldset>
            </form>
          </div>
        </div>

        <div className="board-body board-table" id="idTable">
          <div className="blc-head">
            <ul className="blc-tr">
              <li className="w10">
                <strong>회사명</strong>
              </li>
              <li className="w5">
                <strong>담당자</strong>
              </li>
              <li className="w15">
                <strong>연락처</strong>
              </li>
              <li className="w10">
                <strong>고객사</strong>
              </li>
              <li className="w25">
                <strong>담당지역</strong>
              </li>
              <li className="w10">
                <strong>신뢰도</strong>
              </li>
              <li className="w10">
                <strong>권한</strong>
              </li>
              <li className="w10">
                <strong>적용</strong>
              </li>
            </ul>
          </div>
          <div className="blc-body">
            <ul>
              {userList?.map((item) => (
                <li key={item.user_id}>
                  <ul className="blc-tr" data-id="<%= item.customer_id %>">
                    <li className="ad_m w10">{item.company_name}</li>
                    <li className="ad_m w5">{item.user_name}</li>
                    <li className="ad_m w15">{item.user_tel}</li>
                    <li className="ad_m w10">
                      <button
                        id="btn-search"
                        type="button"
                        className="sellerBtn"
                        onClick={(e) => {
                          openModal(e, item);
                        }}
                      >
                        <img src="/assets/images/admin/icon_more.png" alt="" />
                      </button>
                    </li>
                    <li className="ad_m w25">
                      <input
                        className="input-admin"
                        type="text"
                        defaultValue={item.cleaning_area}
                        onChange={(e) => {
                          setCleaningAreaValue(e, item);
                        }}
                      />
                    </li>
                    <li className="ad_m w10">
                      <Select
                        className="selectAuth"
                        options={selectOptions1}
                        defaultValue={selectOptions1.filter(function (option) {
                          return option.value === item.reliability;
                        })}
                        onChange={(e) => setSelectedReliabilityValue(e, item)}
                      ></Select>
                    </li>

                    <li className="ad_m w10">
                      <Select
                        className="selectAuth"
                        options={selectOptions}
                        defaultValue={selectOptions.filter(function (option) {
                          return option.value === item.user_type;
                        })}
                        onChange={(e) => setSelectedValue(e, item)}
                      ></Select>
                    </li>
                    <li className="ad_m w10">
                      <button
                        id="btn-search"
                        type="button"
                        className="sellerBtn"
                        onClick={(e) => {
                          updateUserList(e, item);
                        }}
                      >
                        <img src="/assets/images/admin/accept.png" alt="" />
                      </button>
                    </li>
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="board-footer">
          <div className="blc-pagenation">
            <Link
              onClick={(e) =>
                getUserListPage(
                  userResult.currentPage - 10 < 1
                    ? 1
                    : userResult.currentPage - 10
                )
              }
              className="blc-first icon-page-first"
            >
              <span className="skip">처음</span>
            </Link>
            <Link
              className="blc-prev icon-page-prev"
              onClick={(e) =>
                getUserListPage(
                  e,
                  userResult.currentPage - 10 < 1
                    ? 1
                    : userResult.currentPage - 10
                )
              }
            >
              <span className="skip">이전</span>
            </Link>
            <ul className="blc-pages">
              {pageList?.map((item) => (
                <li
                  key={item}
                  className={item === userResult.currentPage ? "active" : null}
                >
                  <Link onClick={(e) => getUserListPage(e, item)}>{item}</Link>
                </li>
              ))}
            </ul>
            <Link
              className="blc-next icon-page-next"
              onClick={(e) =>
                getUserListPage(
                  e,
                  pageList[pageList.length - 1] + 1 < userResult.maxPage
                    ? pageList[pageList.length - 1] + 1
                    : pageList[pageList.length - 1]
                )
              }
            >
              <span className="skip">다음</span>
            </Link>
            <Link
              className="blc-last icon-page-last"
              onClick={(e) =>
                getUserListPage(
                  e,
                  pageList[pageList.length - 1] + 1 < userResult.maxPage
                    ? pageList[pageList.length - 1] + 1
                    : pageList[pageList.length - 1]
                )
              }
            >
              <span className="skip">맨끝</span>
            </Link>
          </div>
        </div>
      </div>
      {modalOpen ? (
        <CustomerList closeModal={closeModal} userInfo={selectedUser} />
      ) : null}
    </>
  );
};

export default SubcontractListPage;
