import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import BusinessList from "pages/main/business/businessList";

const BusinessPage = () => {
  const location = useLocation();

  return (
    <div className="container">
      <section className="visual-top cleaning4">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>클리니션의 활동내역을 볼수있습니다</p>
            <h2>현장갤러리</h2>
          </div>
        </div>
      </section>
      <section className="cleaning-section-special">
        <div className="inner">
          <div className="text-area">
            <h2>현장갤러리</h2>
            <div className="ment">
              클리니션은 고객의 니즈에 충족하는 모든 서비스를 제공합니다.
            </div>
          </div>
        </div>
      </section>

      {location.pathname.includes("detail") ? (
        <Outlet />
      ) : location.pathname.includes("write") ? (
        <Outlet />
      ) : location.pathname.includes("edit") ? (
        <Outlet />
      ) : (
        <BusinessList />
      )}
    </div>
  );
};

export default BusinessPage;
