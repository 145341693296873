import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

// const data = [
//   {
//     name: "12월",
//     data: 95,
//   },
//   {
//     name: "1월",
//     data: 99,
//   },
//   {
//     name: "2월",
//     data: 97,
//   },
//   {
//     name: "3월",
//     data: 99,
//   },
//   {
//     name: "4월",
//     data: 98,
//   },
//   {
//     name: "5월",
//     data: 95,
//   },
// ];

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={20} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props) => {
  const { x, y, payload, word } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor="end" stroke="#999999">
        {payload.value}
        {word}
      </text>
    </g>
  );
};

const LineChartMonth = (props) => {
  const lineColor = props.lineColor;
  const word = props.word;
  const data = props.data;

  return (
    <ResponsiveContainer height="100%" width="100%" aspect={1.3}>
      <LineChart
        width={600}
        height={500}
        data={data}
        margin={{
          top: 50,
          right: 10,
          left: -10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
        <YAxis tick={<CustomizedYAxisTick word={word} />} />
        <Line type="line" dataKey="data" stroke={lineColor} strokeWidth="2" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartMonth;
