import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { userInfoState } from "recoil/userStore";
import { useRecoilValue } from "recoil";
import moment from "moment";
import Select from "react-select";
import useAxios from "hooks/useAxios";
import LineChartMonth from "../../../components/common/chart/lineChartMonth";
import BarChartMonth from "../../../components/common/chart/barChartMonth";
import PieChartVisit from "../../../components/common/chart/pieChartVisit";
import PieChartService from "../../../components/common/chart/pieChartService";

const MonthReportPage = (props) => {
  const userID = props.user_id;
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch } = useAxios();
  const [checklistInfo, setChecklistInfo] = useState([]);
  const [daysOption, setDaysOption] = useState([]);
  const [selectedDays, setSelectedDays] = useState();
  const [timeBar, setTimeBar] = useState([]);
  const [totalHour, setTotalHour] = useState();
  const [totalHourBar, setTotalHourBar] = useState("0%");
  const [visitInfo, setVisitInfo] = useState([]);
  const [checkInfo, setCheckInfo] = useState([]);
  const [physicalInfo, setPhysicalInfo] = useState([]);
  const [serviceInfo, setServiceInfo] = useState([]);
  const [complaintInfo, setComplaintInfo] = useState([]);
  const [totalComplaintInfo, setTotalComplaintInfo] = useState(0);
  const [workTimeInfo, setWorkTimeInfo] = useState([]);
  const [totalVisitInfo, setTotalVisitInfo] = useState({
    count: 0,
    total: 0,
    checklist: 0,
  });
  const [contractInfo, setContractInfo] = useState();
  const [days, setDays] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const htmlRef1 = useRef();
  const [managerComment, setManagerComment] = useState("");

  //초기데이터가져오기(계약서, 체크리스트)
  const getContractCheckListInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getContractCheckListInfo,
      requestConfig: {
        user_id: userID,
      },
    })
      .then((res) => {
        if (res.success) {
          setChecklistInfo(res.checklistInfo);
          setDays(res.contractInfo.contract_days.split(","));
          setContractInfo(res.contractInfo);

          let days = getDatesStartToLast(
            moment(getFirstDate(res.contractInfo.contract_start_date)).format(
              "YYYY-MM-DD"
            ),
            moment().endOf("month").format("YYYY-MM-DD")
          );
          setDaysOption(() =>
            days.map((day) => ({
              value: day,
              label: day
                .substr(0, 4)
                .concat("년")
                .concat(" ")
                .concat(day.substr(5, 2))
                .concat("월"),
            }))
          );
          setSelectedDays(moment().startOf("month").format("YYYY-MM-DD"));
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getContractCheckListInfo : ", err);
      });
  };

  //계약기간 총 방문횟수, 오늘까지 방문횟수, 체크리스트갯수
  const countContractVisite = () => {
    setTotalVisitInfo({
      total: countDays(
        contractInfo?.contract_start_date,
        contractInfo?.contract_end_date
      ),
      count: countDays(contractInfo?.contract_start_date, new Date()),
      checklist: countContractCheckList(),
    });
  };

  //계약기간 총 체크리스트갯수
  const countContractCheckList = () => {
    let startDate = moment(contractInfo?.contract_start_date).format(
      "YYYY-MM-DD"
    );
    let endDate = moment(contractInfo?.contract_end_date)
      .add(1, "day")
      .format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    return checklist?.length;
  };

  //기간중 계약서에 명시된 요일에 대한 날짜 카운트
  const countDays = (start, end) => {
    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let count = 0;
    let temp_date = startDate;
    let dates = [];

    if (days !== null) {
      while (true) {
        if (temp_date > endDate) {
          break;
        } else {
          let tmp = moment(temp_date).day() - 1;
          if (tmp === -1) tmp = 6;
          if (days[tmp] === "1") {
            count++;
            dates.push(new Date(temp_date));
          }
          temp_date = moment(temp_date).add(1, "day").format("YYYY-MM-DD");
        }
      }
    }

    return count;
  };

  //계약시작~오늘까지 체크리스트 중 불만족 갯수를 구해서 만족도 구하기
  const countTotalComplaint = () => {
    setTotalComplaintInfo(totalComplaint);
  };

  const totalComplaint = () => {
    let startDate = moment(contractInfo?.contract_start_date).format(
      "YYYY-MM-DD"
    );
    let endDate = moment().format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return 0;

    let complaint = checklist.filter(
      (el) => el.reply_comment !== null && el.reply_comment.length > 0
    );

    if (complaint.length === 0) {
      return 0;
    } else {
      return 100 - Math.round((complaint.length * 100) / checklist.length);
    }
  };

  const countVisitTime = (date) => {
    let startDate = moment(date).format("YYYY-MM-DD");
    let endDate = moment(date).add(1, "month").format("YYYY-MM-DD");
    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    let totalMinute = checklist.reduce(function add(sum, currValue) {
      return (
        sum +
        moment(currValue.check_end_date).diff(
          moment(currValue.check_start_date),
          "minutes"
        )
      );
    }, 0);

    let workDays = countDays(
      moment().startOf("month").format("YYYY-MM-DD"),
      moment().endOf("month").format("YYYY-MM-DD")
    );

    let monthHour = Math.round(
      (workDays * contractInfo?.contract_total_time) / 60
    );
    monthHour = ((monthHour % 6) + 1) * 6;

    let numbers = [1, 2, 3, 4, 5, 6];
    setTimeBar(() => numbers.map((data) => ({ data: (monthHour / 6) * data })));

    let hour = Math.round(totalMinute / 60);
    setTotalHour(hour);
    setTotalHourBar(Math.round((hour / monthHour) * 100) + "%");
  };

  const countVisite = (data) => {
    let list = makeMonthData(data);

    list.map((el) => {
      el.check = totalCheck(el.date);
      el.visit =
        moment(el.date)
          .endOf("M")
          .diff(moment(contractInfo.contract_start_date)) < 0
          ? 0
          : countDays(
              moment(el.date).startOf("month").format("YYYY-MM-DD"),
              moment(el.date).endOf("month").format("YYYY-MM-DD")
            );
    });

    setCheckInfo(() =>
      list.map((data) => ({
        data: data.check,
        name: data.name + "월",
      }))
    );

    setVisitInfo(() =>
      list.map((data) => ({
        data: data.visit,
        name: data.name + "월",
      }))
    );
  };

  const totalVisite = (data) => {
    let startDate = moment(data).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(data).endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    return checklist?.length;
  };

  const totalCheck = (data) => {
    let startDate = moment(data).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(data).endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    return checklist?.length;
  };

  const countPhysical = (data) => {
    let list = makeMonthData(data);

    list.map((el) => {
      el.data = totalPhysical(el.date);
    });

    setPhysicalInfo(() =>
      list.map((data) => ({
        data: data.data,
        name: data.name + "월",
      }))
    );
  };

  const totalPhysical = (data) => {
    let startDate = moment(data).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(data).endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return 0;

    let totalCount = 0;
    let checkCount = 0;
    checklist.map((el) => {
      totalCount += el.check_physical_name.split(",").length;
      checkCount += el.check_physicals.split("0").length - 1;
    });

    if (checkCount === 0) {
      return 100;
    } else {
      return Math.round(((totalCount - checkCount) * 100) / totalCount);
    }
  };

  const countService = (data) => {
    let list = makeMonthData(data);

    list.map((el) => {
      el.data = totalService(el.date);
    });

    setServiceInfo(() =>
      list.map((data) => ({
        data: data.data,
        name: data.name + "월",
      }))
    );
  };

  const totalService = (data) => {
    let startDate = moment(data).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(data).endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return 0;

    let totalCount = 0;
    let checkCount = 0;
    checklist.map((el) => {
      totalCount += el.check_service_name.split(",").length;
      checkCount += el.check_services.split("0").length - 1;
    });

    if (checkCount === 0) {
      return 100;
    } else {
      return Math.round(((totalCount - checkCount) * 100) / totalCount);
    }
  };

  const countMonthComplaint = (data) => {
    let list = makeMonthData(data);

    list.map((el) => {
      el.data = monthComplaint(el.date);
    });

    setComplaintInfo(() =>
      list.map((data) => ({
        data: data.data,
        name: data.name + "월",
      }))
    );
  };

  const monthComplaint = (data) => {
    let startDate = moment(data).startOf("month").format("YYYY-MM-DD");
    let endDate = moment(data).endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return 0;

    let complaint = checklist.filter(
      (el) => el.reply_comment !== null && el.reply_comment.length > 0
    );

    if (complaint.length === 0) {
      return 100;
    } else {
      return 100 - Math.round((complaint.length * 100) / checklist.length);
    }
  };

  const countWorkTime = (data) => {
    let list = makeMonthData(data);

    list.map((el) => {
      el.data = totalWorkTime(el.date);
    });

    setWorkTimeInfo(() =>
      list.map((data) => ({
        data: data.data,
        name: data.name + "월",
      }))
    );
  };

  const totalWorkTime = (data, number) => {
    let startDate = moment(data)
      .subtract(number, "M")
      .startOf("month")
      .format("YYYY-MM-DD");
    let endDate = moment(data)
      .subtract(number, "M")
      .startOf("month")
      .add(1, "M")
      .format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return 0;

    let totalMinute = checklist.reduce(function add(sum, currValue) {
      return (
        sum +
        moment(currValue.check_end_date).diff(
          moment(currValue.check_start_date),
          "minutes"
        )
      );
    }, 0);

    return Math.round(totalMinute / checklist.length);
  };

  const selectedDayHandler = (data) => {
    setSelectedDays(data);
    countVisitTime(moment(data).startOf("month").format("YYYY-MM-DD"));
    countVisite(moment(data).startOf("month").format("YYYY-MM-DD"));
    countPhysical(moment(data).startOf("month").format("YYYY-MM-DD"));
    countService(moment(data).startOf("month").format("YYYY-MM-DD"));
    countMonthComplaint(moment(data).startOf("month").format("YYYY-MM-DD"));
    countWorkTime(moment(data).startOf("month").format("YYYY-MM-DD"));
  };

  const Page1Content = () => (
    <>
      <div
        className="page print page-break dashboard-area webpart-area"
        style={{ background: "#F7F7F7" }}
      >
        <div
          className="sub-title"
          style={{ marginTop: "100px", marginBottom: "70px", width: "100%" }}
        >
          <img src="/assets/images/common/logo.png" alt="로고" />
          <h1>ZIM(Zone Interactive Manager)</h1>
          <h1 style={{ fontSize: "56px", marginTop: "70px" }}>
            {contractInfo?.company_name} -
            {moment(selectedDays).format("YYYY.MM")} 월간보고서
          </h1>
          <h1 style={{ fontSize: "32px" }}>
            담당매니저: {contractInfo?.manager_name}
          </h1>
        </div>
        <div
          className="webpart-item webpart-visit-time"
          style={{ marginTop: "70px" }}
        >
          <h2>월간 방문시간</h2>
          <div className="monthly-visit-chart">
            <div className="text"></div>
            <div className="chart-bar">
              {/* left에 값을 %로 환산  */}
              <span className="current" style={{ left: totalHourBar }}>
                {totalHour}H
              </span>
              <ul className="list">
                {timeBar?.map((item) => (
                  <li key={item.data}>
                    <span>{item.data}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div
          className="webpart-item webpart-complex"
          style={{ marginTop: "70px" }}
        >
          <div className="webpart-three-item webpart-visits">
            <h2>계약된 방문횟수</h2>
            <div className="chart-explain-box">
              <div className="chart-area">
                <PieChartVisit
                  count={totalVisitInfo.count}
                  totalCount={totalVisitInfo.total}
                  type="N"
                />
              </div>
              <div className="text-area">
                <ul>
                  <li>
                    <i className="white"></i>
                    <span>총 방문횟수</span>
                    <strong>{totalVisitInfo.total}회</strong>
                  </li>
                  <li>
                    <i className="purple"></i>
                    <span>오늘까지 방문 횟수</span>
                    <strong>{totalVisitInfo.count}회</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="webpart-three-item webpart-complain">
            <h2>서비스 만족도</h2>
            <div className="chart-explain-box">
              <div className="chart-area-service">
                <PieChartService count={totalComplaintInfo} totalCount="100" />
              </div>
              <div className="text-area">
                <ul>
                  <li>
                    <i className="gray"></i>
                    <span>전체 진행률</span>
                    <strong>100%</strong>
                  </li>
                  <li>
                    <i className="green"></i>
                    <span>만족도</span>
                    <strong>{totalComplaintInfo}%</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="webpart-three-item webpart-check">
            <h2>리포트 작성</h2>
            <div className="chart-explain-box">
              <div className="chart-area-service">
                <PieChartVisit
                  count={totalVisitInfo.checklist}
                  totalCount={totalVisitInfo.count}
                  type="C"
                />
              </div>
              <div className="text-area">
                <ul>
                  <li>
                    <i className="white"></i>
                    <span>오늘까지 방문 횟수</span>
                    <strong>{totalVisitInfo.count}회</strong>
                  </li>
                  <li>
                    <i className="purple"></i>
                    <span>리포트 횟수</span>
                    <strong>{totalVisitInfo.checklist}회</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="webpart-item webpart-complex"
          style={{ marginTop: "70px" }}
        >
          <div className="webpart-complex-item webpart-monthly-visit">
            <h2>월간서비스 방문횟수(최근6개월)</h2>
            <BarChartMonth data={visitInfo} colors={["#623189", "#7445F7"]} />
          </div>

          <div className="webpart-complex-item webpart-daily-checked">
            <h2>일일체크리스트 작성횟수(최근6개월)</h2>
            <BarChartMonth data={checkInfo} colors={["#FE8954", "#E64F67"]} />
          </div>
        </div>
      </div>
      <div
        className="page print page-break report-area webpart-area"
        style={{ background: "#F7F7F7" }}
      >
        <div
          className="webpart-item webpart-service-range-rate x2 xy"
          style={{ marginTop: "70px" }}
        >
          <h2>물리적범위 서비스 이행율</h2>
          <LineChartMonth data={physicalInfo} lineColor="#29ABE2" word="%" />
        </div>

        <div
          className="webpart-item webpart-service-list-rate x2 xy"
          style={{ marginTop: "70px" }}
        >
          <h2>서비스항목이행율</h2>
          <LineChartMonth data={serviceInfo} lineColor="#623189" word="%" />
        </div>

        <div className="webpart-item webpart-complain-rate x2 xy">
          <h2>서비스만족도</h2>
          <LineChartMonth data={complaintInfo} lineColor="#FE8954" word="%" />
        </div>

        <div className="webpart-item webpart-clean-complete x2 xy">
          <h2>청소시간 평균</h2>
          <LineChartMonth data={workTimeInfo} lineColor="#7445F7" word="분" />
        </div>
        <div
          className="webpart-item webpart-complain-rate xy"
          style={{ minHeight: "300px" }}
        >
          <h2>매니저 의견</h2>
          <h3 style={{ marginTop: "30px" }}>{managerComment}</h3>
        </div>
      </div>
    </>
  );

  const handlePrint = useReactToPrint({
    content: () => htmlRef1.current,
    onBeforePrint: () => setIsPrint(true), // 프린트 시작 전 상태 변경
    onAfterPrint: () => setIsPrint(false), // 프린트 완료 후 상태 변경
  });

  useEffect(() => {
    if (userID !== undefined) {
      getContractCheckListInfo();
    }
  }, []);

  useEffect(() => {
    getContractCheckListInfo();
  }, [userID]);

  useEffect(() => {
    if (checklistInfo.length > 0) {
      countVisitTime(moment().startOf("month").format("YYYY-MM-DD"));
      countVisite(moment().startOf("month").format("YYYY-MM-DD"));
      countPhysical(moment().startOf("month").format("YYYY-MM-DD"));
      countService(moment().startOf("month").format("YYYY-MM-DD"));
      countMonthComplaint(moment().startOf("month").format("YYYY-MM-DD"));
      countWorkTime(moment().startOf("month").format("YYYY-MM-DD"));
    }
  }, [checklistInfo]);

  useEffect(() => {
    countContractVisite();
    countTotalComplaint();
  }, [contractInfo]);

  return (
    <>
      <div className="content">
        <div>
          <div className="title">
            <div>
              <h1>ZIM(Zone Interactive Manager)</h1>
              <p>계약서에 명시된 기간에 대한 데이터를 확인하세요.</p>
            </div>
            {userInfo?.user_type === "A" ? (
              <button className="report" onClick={handlePrint}>
                <img src="/assets/images/common/print.png" alt="" />
              </button>
            ) : null}
          </div>
          <div className="dashboard-area webpart-area">
            <div className="webpart-item webpart-complex">
              <div className="webpart-three-item webpart-visits">
                <h2>계약된 방문횟수</h2>
                <div className="chart-explain-box">
                  <div className="chart-area">
                    <PieChartVisit
                      count={totalVisitInfo.count}
                      totalCount={totalVisitInfo.total}
                      type="N"
                    />
                  </div>
                  <div className="text-area">
                    <ul>
                      <li>
                        <i className="white"></i>
                        <span>총 방문횟수</span>
                        <strong>{totalVisitInfo.total}회</strong>
                      </li>
                      <li>
                        <i className="purple"></i>
                        <span>오늘까지 방문 횟수</span>
                        <strong>{totalVisitInfo.count}회</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="webpart-three-item webpart-complain">
                <h2>서비스 만족도</h2>
                <div className="chart-explain-box">
                  <div className="chart-area-service">
                    <PieChartService
                      count={totalComplaintInfo}
                      totalCount="100"
                    />
                  </div>
                  <div className="text-area">
                    <ul>
                      <li>
                        <i className="gray"></i>
                        <span>전체 진행률</span>
                        <strong>100%</strong>
                      </li>
                      <li>
                        <i className="green"></i>
                        <span>만족도</span>
                        <strong>{totalComplaintInfo}%</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="webpart-three-item webpart-check">
                <h2>리포트 작성</h2>
                <div className="chart-explain-box">
                  <div className="chart-area-service">
                    <PieChartVisit
                      count={totalVisitInfo.checklist}
                      totalCount={totalVisitInfo.count}
                      type="C"
                    />
                  </div>
                  <div className="text-area">
                    <ul>
                      <li>
                        <i className="white"></i>
                        <span>오늘까지 방문 횟수</span>
                        <strong>{totalVisitInfo.count}회</strong>
                      </li>
                      <li>
                        <i className="purple"></i>
                        <span>리포트 횟수</span>
                        <strong>{totalVisitInfo.checklist}회</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-title">
              <h1>ZIM(Zone Interactive Manager)</h1>
              <p>월별 데이터를 확인하세요.</p>
            </div>
            <div className="webpart-item webpart-visit-time">
              <h2>월간 방문시간</h2>
              <div className="monthly-visit-chart">
                <div className="text"></div>
                <div className="chart-bar">
                  {/* left에 값을 %로 환산  */}
                  <span className="current" style={{ left: totalHourBar }}>
                    {totalHour}H
                  </span>
                  <ul className="list">
                    {timeBar?.map((item) => (
                      <li key={item.data}>
                        <span>{item.data}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="report-date">
          <div className="r-date report-date-item report-date-year">
            <label for="select01">연도/월 선택</label>
            <div className="select-area">
              <Select
                id="select01"
                options={daysOption}
                value={daysOption.filter(function (option) {
                  return option.value === selectedDays;
                })}
                onChange={(e) => selectedDayHandler(e.value)}
              ></Select>
            </div>
          </div>
        </div>
        <div className="report-area webpart-area">
          <div className="webpart-item webpart-complex">
            <div className="webpart-complex-item webpart-monthly-visit">
              <h2>월간서비스 방문횟수(최근6개월)</h2>
              <BarChartMonth data={visitInfo} colors={["#623189", "#7445F7"]} />
            </div>

            <div className="webpart-complex-item webpart-daily-checked">
              <h2>일일체크리스트 작성횟수(최근6개월)</h2>
              <BarChartMonth data={checkInfo} colors={["#FE8954", "#E64F67"]} />
            </div>
          </div>

          <div className="webpart-item webpart-service-range-rate x2 xy">
            <h2>물리적범위 서비스 이행율</h2>
            <LineChartMonth data={physicalInfo} lineColor="#29ABE2" word="%" />
          </div>

          <div className="webpart-item webpart-service-list-rate x2 xy">
            <h2>서비스항목이행율</h2>
            <LineChartMonth data={serviceInfo} lineColor="#623189" word="%" />
          </div>

          <div className="webpart-item webpart-complain-rate  x2 xy">
            <h2>서비스만족도</h2>
            <LineChartMonth data={complaintInfo} lineColor="#FE8954" word="%" />
          </div>

          <div className="webpart-item webpart-clean-complete x2 xy">
            <h2>청소시간 평균</h2>
            <LineChartMonth data={workTimeInfo} lineColor="#7445F7" word="분" />
          </div>
        </div>
        {userInfo?.user_type === "A" ? (
          <div className="adi-content">
            <h1>매니저의견</h1>
            <textarea
              className="textarea"
              cols="30"
              rows="10"
              defaultValue={managerComment}
              onChange={(e) => setManagerComment(e.target.value)}
            ></textarea>
          </div>
        ) : null}

        <div ref={htmlRef1} className="page print page-break">
          {userInfo?.user_type === "A" ? <Page1Content /> : null}
        </div>
      </div>
    </>
  );
};

const getDatesStartToLast = (startDate, lastDate) => {
  var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
  if (!(regex.test(startDate) && regex.test(lastDate)))
    return "Not Date Format";
  var result = [];
  var curDate = new Date(startDate);
  while (curDate <= new Date(lastDate)) {
    result.push(curDate.toISOString().split("T")[0]);
    curDate.setMonth(curDate.getMonth() + 1);
  }
  return result;
};

const getFirstDate = (date) => {
  let y = new Date(date).getFullYear();
  let m = new Date(date).getMonth();
  return new Date(y, m, 1);
};

const makeMonthData = (data) => {
  let list = [];
  list.push({
    name: moment(data).subtract(6, "M").month() + 1,
    date: moment(data).subtract(6, "M"),
  });
  list.push({
    name: moment(data).subtract(5, "M").month() + 1,
    date: moment(data).subtract(5, "M"),
  });
  list.push({
    name: moment(data).subtract(4, "M").month() + 1,
    date: moment(data).subtract(4, "M"),
  });
  list.push({
    name: moment(data).subtract(3, "M").month() + 1,
    date: moment(data).subtract(3, "M"),
  });
  list.push({
    name: moment(data).subtract(2, "M").month() + 1,
    date: moment(data).subtract(2, "M"),
  });
  list.push({
    name: moment(data).subtract(1, "M").month() + 1,
    date: moment(data).subtract(1, "M"),
  });

  return list;
};

export default MonthReportPage;
