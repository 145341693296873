import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

const data1 = [
  {
    name: "11월",
    data: 4,
  },
  {
    name: "12월",
    data: 11,
  },
  {
    name: "2월",
    data: 14,
  },
  {
    name: "2월",
    data: 12,
  },
  {
    name: "3월",
    data: 10,
  },
  {
    name: "4월",
    data: 12,
  },
];

const BarChartMonth = (props) => {
  const colors = props.colors;
  const data = props.data;

  return (
    <ResponsiveContainer height="100%" width="100%" aspect={1.3}>
      <BarChart
        width={600}
        height={300}
        data={data}
        margin={{
          top: 30,
          right: 20,
          left: -20,
          bottom: 0,
        }}
      >
        <CartesianGrid
          vertical={false}
          stroke="#DEEBFF"
          strokeDasharray="1 0"
        />
        <XAxis dataKey="name" />
        <YAxis />
        <Bar
          barSize={20}
          dataKey="data"
          fill="#8884d8"
          label={{ position: "top" }}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={index === 5 ? colors[1] : colors[0]}
              width={20}
              radius={10}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartMonth;
