import React, { useEffect, useState } from "react";
import useAxios from "hooks/useAxios";
import DatePickerTimeComponent from "../common/datePickerTimeComponent";
import ImageInfo from "components/popup/imageInfo";

const CheckListInfo = (props) => {
  const checkList = props.checkList;
  const { apiUrls, axiosFetch } = useAxios();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [physicals, setPhysicals] = useState();
  const [services, setServices] = useState();
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [images, setImages] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const baseURL =
    process.env.NODE_ENV === "development" ? "http://localhost:8915/" : `/`;

  const physicalCheckboxHandler = (data, index) => {
    setPhysicals((physicals) =>
      physicals.map((physical, currentIndex) =>
        currentIndex === index ? { ...physical, checked: data } : physical
      )
    );
  };

  const serviceCheckboxHandler = (data, index) => {
    setServices((services) =>
      services.map((service, currentIndex) =>
        currentIndex === index ? { ...service, checked: data } : service
      )
    );
  };

  const saveCheckList = () => {
    checkList.check_start_date = selectedStartDate;
    checkList.check_end_date = selectedEndDate;
    checkList.check_comment = comment;

    let physicalStr = "";
    physicals.map((physical) => {
      if (physical.checked) {
        physicalStr += "1,";
      } else physicalStr += "0,";
    });
    let serviceStr = "";
    services.map((service) => {
      if (service.checked) {
        serviceStr += "1,";
      } else serviceStr += "0,";
    });
    checkList.check_physicals = physicalStr.substring(
      0,
      physicalStr.length - 1
    );
    checkList.check_services = serviceStr.substring(0, serviceStr.length - 1);

    axiosFetch({
      method: "post",
      url: apiUrls.updateCheckList,
      requestConfig: {
        checkListInfo: checkList,
      },
    })
      .then((res) => {
        alert(res.message);
      })
      .catch((err) => {
        console.log("[ERROR] getServiceListU : ", err);
      });

    return;
  };

  const deleteCheckList = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.delCheckList,
      requestConfig: {
        check_id: checkList.check_id,
        image_files: checkList.image_files,
      },
    })
      .then((res) => {
        alert(res.message);
        if (res.success) {
          props.closeModal();
        }
      })
      .catch((err) => {
        console.log("[ERROR] getServiceListU : ", err);
      });
  };

  const openModal = (e, item) => {
    e.preventDefault();
    setModalOpen(true);
    setSelectedImage(item);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const checkedPhysicalsHandler = (e) => {
    e.preventDefault();
    setPhysicals((physicals) =>
      physicals.map((physical) => ({ ...physical, checked: true }))
    );
  };

  const checkedServiceHandler = (e) => {
    e.preventDefault();
    setServices((services) =>
      services.map((services) => ({ ...services, checked: true }))
    );
  };

  useEffect(() => {
    setSelectedStartDate(new Date(checkList?.check_start_date));
    setSelectedEndDate(new Date(checkList?.check_end_date));

    let physicalArray = checkList?.check_physical_name?.split(",");
    let physicalValue = checkList?.check_physicals?.split(",");
    setPhysicals(() =>
      physicalArray.map((physical, index) => ({
        name: physical,
        checked: physicalValue[index] === "1" ? true : false,
      }))
    );

    let serviceArray = checkList?.check_service_name?.split(",");
    let serviceValue = checkList?.check_services?.split(",");
    setServices(() =>
      serviceArray.map((service, index) => ({
        name: service,
        checked: serviceValue[index] === "1" ? true : false,
      }))
    );

    let imageArray = checkList?.image_files?.split(",");
    setImages(() =>
      imageArray.map((image) => ({
        fileName: image,
      }))
    );

    setComment(checkList?.check_comment);
    setReply(checkList?.reply_comment);
  }, []);

  return (
    <>
      <div className="admin-dialog dialog-wrap" id="modal_admin_detail">
        <div className="dialog-box admin-dialog-box contract">
          <div className="admin-dialog-area">
            <div className="title-area">
              <h2>계약내용/협력사용</h2>
            </div>
            <div className="dialog-content">
              <div className="admin-dialog-item">
                <h3 className="adi-title">고객사</h3>
                <div className="adi-content">{checkList.company_name}</div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">주소지</h3>
                <div className="adi-content">
                  {checkList.company_addr1}
                  {checkList.company_addr2}
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">방문시작</h3>
                <div className="adi-content">
                  <DatePickerTimeComponent
                    selected={selectedStartDate}
                    onChange={setSelectedStartDate}
                    withPortal={false}
                  />
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">방문완료</h3>
                <div className="adi-content time-start-end">
                  <DatePickerTimeComponent
                    selected={selectedEndDate}
                    onChange={setSelectedEndDate}
                    withPortal={false}
                  />
                </div>
              </div>

              <div className="admin-dialog-item">
                <h3
                  className="adi-title"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => checkedPhysicalsHandler(e)}
                >
                  물리적 범위
                </h3>
                <div className="adi-content">
                  <ul className="adi-item-row">
                    {physicals?.map((item, index) => (
                      <li>
                        <div className="input-check-zim">
                          <input
                            key={item.name}
                            type="checkbox"
                            id={`inpp0${index}`}
                            checked={item.checked}
                            onChange={(e) => {
                              physicalCheckboxHandler(e.target.checked, index);
                            }}
                          />
                          <label for={`inpp0${index}`}>{item.name}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3
                  className="adi-title"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => checkedServiceHandler(e)}
                >
                  서비스 항목
                </h3>
                <div className="adi-content">
                  <ul className="adi-item-row">
                    {services?.map((item, index) => (
                      <li>
                        <div className="input-check-zim">
                          <input
                            key={item.name}
                            type="checkbox"
                            id={`inps0${index}`}
                            checked={item.checked}
                            onChange={(e) => {
                              serviceCheckboxHandler(e.target.checked, index);
                            }}
                          />
                          <label for={`inps0${index}`}>{item.name}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">완료사진</h3>
                <div className="adi-content">
                  <ul className="adi-pictures">
                    {images?.map((item) => (
                      <li onClick={(e) => openModal(e, item)}>
                        <img src={`${baseURL}${item.fileName}`} alt="" />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">
                  메모사항
                  <br />
                  (협력사)
                </h3>
                <div className="adi-content">
                  <textarea
                    className="textarea"
                    cols="30"
                    rows="10"
                    defaultValue={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">
                  메모사항
                  <br />
                  (고객사)
                </h3>
                <div className="adi-content">
                  <textarea
                    className="textarea"
                    cols="30"
                    rows="10"
                    defaultValue={reply}
                    onChange={(e) => setReply(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="btn-area">
                <button
                  type="button"
                  style={{
                    background: "#623189",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={deleteCheckList}
                >
                  삭제
                </button>
                <button
                  type="button"
                  style={{
                    background: "#623189",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={saveCheckList}
                >
                  수정
                </button>
                <button type="button" onClick={props.closeModal}>
                  닫기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen ? (
        <ImageInfo closeModal={closeModal} selectedImage={selectedImage} />
      ) : null}
    </>
  );
};

export default CheckListInfo;
