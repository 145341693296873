import React, { useState } from "react";
import { useForm } from "react-hook-form";
import InputUnit from "components/InputUnit";
import useAxiosFunction from "hooks/useAxios";
import { CustomRegExp } from "helpers/customRegExp";

const RequestConsultingPage = () => {
  const [textValue, setTextValue] = useState("");
  const [checkAgree, setCheckAgree] = useState(false);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const sendEmail = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.sendEmail,
      requestConfig: reqData,
    })
      .then((res) => {
        alert(res.message);
      })
      .catch((err) => {
        console.log("[ERROR] sendEmail : ", err);
      });
  };

  const handleSetValue = (e) => {
    setTextValue(e.target.value);
  };

  const changeHandler = (e) => {
    setCheckAgree(e.target.checked);
  };

  const handleSetTab = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      let val = e.target.value;
      let start = e.target.selectionStart;
      let end = e.target.selectionEnd;
      e.target.value = val.substring(0, start) + "\t" + val.substring(end);
      e.target.selectionStart = e.target.selectionEnd = start + 1;
      handleSetValue(e);
      return false;
    }
  };

  return (
    <div className="container">
      <section className="visual-top inquiry">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>클리니션의 공간컨설팅은 클리닝서비스의 시작입니다</p>
            <h2>INQUIRY</h2>
          </div>
        </div>
      </section>
      <section className="inquiry-section">
        <div className="inner">
          <h2>공간컨설팅 신청</h2>
          <div className="form-area">
            <form onSubmit={handleSubmit(sendEmail)}>
              <h3>요청사항</h3>
              <div className="writting-list">
                <div className="form-item text">
                  <label className="star" for="inquiry_name">
                    이름
                  </label>
                  <InputUnit
                    label="inquiry_name"
                    type="text"
                    holder=" "
                    register={register("inquiry_name", {
                      required: "이름은 필수 입력 값 입니다.",
                      pattern: CustomRegExp("name"),
                    })}
                    errors={formErrors}
                    iMaxlength={10}
                  />
                </div>
                <div className="form-item text">
                  <label className="star" for="inquiry_tel">
                    연락처
                  </label>
                  <InputUnit
                    label="inquiry_tel"
                    type="text"
                    holder=" "
                    register={register("inquiry_tel", {
                      required: "연락처는 필수 입력 값 입니다.",
                      pattern: CustomRegExp("tel"),
                    })}
                    errors={formErrors}
                    iMaxlength={12}
                  />
                </div>
                <div className="form-item text">
                  <label className="star" for="inquiry_email">
                    Email
                  </label>
                  <InputUnit
                    label="inquiry_email"
                    holder=" "
                    register={register("inquiry_email", {
                      pattern: CustomRegExp("email"),
                    })}
                    errors={formErrors}
                    iMaxlength={50}
                  />
                </div>
                <div className="form-item text">
                  <label className="star" for="inquiry_company">
                    회사명
                  </label>
                  <InputUnit
                    label="inquiry_company"
                    holder=" "
                    register={register("inquiry_company")}
                    errors={formErrors}
                    iMaxlength={10}
                  />
                </div>
                <div className="form-item select">
                  <label for="inquiry_service" className="star">
                    서비스
                  </label>
                  <select
                    name="inquiry_service"
                    id="inquiry_service"
                    {...register("inquiry_service")}
                  >
                    <option value="정기클리닝">정기클리닝</option>
                    <option value="비정기클리닝">비정기클리닝</option>
                    <option value="특수클리닝">특수클리닝</option>
                  </select>
                </div>
                <div className="form-item text-area">
                  <label className="star" for="inquiry_request">
                    요청
                    <br />
                    서비스
                  </label>
                  <textarea
                    id="inquiry_request"
                    textAlignVertical="top"
                    multiline={true}
                    placeholder="예시) 사무실인데 바닥 청소와 쓰레기 배출해 주세요"
                    {...register("inquiry_request")}
                    value={textValue}
                    onChange={(e) => handleSetValue(e)}
                    onKeyDown={(e) => handleSetTab(e)}
                  />
                </div>
              </div>
              <div className="agree-area">
                <div className="radio-style">
                  <input
                    label="inquiry_agree"
                    id="inquiry_agree"
                    type="checkbox"
                    holder=" "
                    {...register("inquiry_agree")}
                    checked={checkAgree}
                    onChange={changeHandler}
                  />
                  <label for="inquiry_agree">
                    개인정보 처리방침에 동의합니다.
                  </label>
                </div>
              </div>
              <div className="button-area">
                <button type="submit" className="button-primary size-l">
                  <span>보내기</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestConsultingPage;
