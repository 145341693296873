import React, { useEffect, useState } from "react";
import Select from "react-select";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import DatePickerComponent from "../common/datePickerComponent";
import moment from "moment";

const ContractInfo = (props) => {
  const userInfo = props.userInfo;
  const closeModal = props.closeModal;
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [contractInfo, setContractInfo] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [amount, setAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState(0);
  const [partnerCompanyName, setPartnerCompanyName] = useState("");
  const [partnerCompanyNumber, setPartnerCompanyNumber] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [partnerTel, setPartnerTel] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerTel, setManagerTel] = useState("");
  const [partnerAmount, setPartnerAmount] = useState("");
  const [contractTotalTime, setContractTotalTime] = useState("");
  const [days, setDays] = useState([
    { name: "월", checked: false },
    { name: "화", checked: false },
    { name: "수", checked: false },
    { name: "목", checked: false },
    { name: "금", checked: false },
    { name: "토", checked: false },
    { name: "일", checked: false },
  ]);
  const [selectedPlace, setSelectedPlace] = useState();
  const [physicals, setPhysicals] = useState();
  const physicalData = [
    {
      place: "건물관리",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "로비", checked: false },
        { name: "계단", checked: false },
        { name: "휴게실", checked: false },
        { name: "엘리베이터", checked: false },
        { name: "테라스", checked: false },
        { name: "주차장", checked: false },
        { name: "흡연구역", checked: false },
        { name: "창고", checked: false },
        { name: "화장실", checked: false },
        { name: "건물주변", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "일반기업",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "사무공간", checked: false },
        { name: "회의실", checked: false },
        { name: "휴게실", checked: false },
        { name: "탕비실", checked: false },
        { name: "임원실", checked: false },
        { name: "식당", checked: false },
        { name: "계단", checked: false },
        { name: "강당", checked: false },
        { name: "창고", checked: false },
        { name: "화장실", checked: false },
        { name: "건물주변", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "상업시설",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "매장", checked: false },
        { name: "계산대", checked: false },
        { name: "주방", checked: false },
        { name: "회의룸", checked: false },
        { name: "직원공간", checked: false },
        { name: "대기실", checked: false },
        { name: "키즈룸", checked: false },
        { name: "흡연실", checked: false },
        { name: "화장실", checked: false },
        { name: "창고", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "의료시설",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "대기실", checked: false },
        { name: "상담실", checked: false },
        { name: "진료실", checked: false },
        { name: "회복실", checked: false },
        { name: "검사실", checked: false },
        { name: "입원실", checked: false },
        { name: "창고", checked: false },
        { name: "엘리베이터", checked: false },
        { name: "직원공간", checked: false },
        { name: "화장실", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "교육시설",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "로비", checked: false },
        { name: "강의실", checked: false },
        { name: "교무실", checked: false },
        { name: "독서실", checked: false },
        { name: "창고", checked: false },
        { name: "탕비실", checked: false },
        { name: "화장실", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "주거시설",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "계단", checked: false },
        { name: "엘리베이터", checked: false },
        { name: "주거공간", checked: false },
        { name: "주방", checked: false },
        { name: "화장실", checked: false },
        { name: "분리수거장", checked: false },
        { name: "건물주변", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "운동시설",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "운동공간", checked: false },
        { name: "직원공간", checked: false },
        { name: "운동기구", checked: false },
        { name: "탈의실", checked: false },
        { name: "샤워실", checked: false },
        { name: "창고", checked: false },
        { name: "화장실", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "숙박시설",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "안내데스크", checked: false },
        { name: "숙박공간", checked: false },
        { name: "계단", checked: false },
        { name: "엘리베이터", checked: false },
        { name: "싱크대", checked: false },
        { name: "세면대", checked: false },
        { name: "창고", checked: false },
        { name: "화장실", checked: false },
        { name: "분리수거장", checked: false },
        { name: "건물주변", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
    {
      place: "종교시설",
      data: [
        { name: "출입문", checked: false },
        { name: "복도", checked: false },
        { name: "예배당", checked: false },
        { name: "회의실", checked: false },
        { name: "교육실", checked: false },
        { name: "직원공간", checked: false },
        { name: "엘리베이터", checked: false },
        { name: "화장실", checked: false },
        { name: "계단", checked: false },
        { name: "건물주변", checked: false },
        { name: "창고", checked: false },
        { name: "기타공간", checked: false },
      ],
    },
  ];
  const [services, setServices] = useState([
    { name: "운영시스템(ZIM)", checked: false },
    { name: "바닥 클리닝", checked: false },
    { name: "쓰레기 배출", checked: false },
    { name: "분리수거", checked: false },
    { name: "내부집기 클리닝", checked: false },
    { name: "유리창 클리닝", checked: false },
    { name: "출입문 관리", checked: false },
    { name: "창틀 먼지제거", checked: false },
    { name: "난간 클리닝", checked: false },
    { name: "변기 클리닝", checked: false },
    { name: "세면대 클리닝", checked: false },
    { name: "비품관리", checked: false },
    { name: "상주 인원 파견", checked: false },
  ]);
  const selectPlaceOptions = [
    { value: "0", label: "건물관리" },
    { value: "1", label: "일반기업" },
    { value: "2", label: "상업시설" },
    { value: "3", label: "의료시설" },
    { value: "4", label: "교육시설" },
    { value: "5", label: "주거시설" },
    { value: "6", label: "운동시설" },
    { value: "7", label: "숙박시설" },
    { value: "8", label: "종교시설" },
  ];
  const [comment, setComment] = useState("");

  const selectedPlaceHandler = (data) => {
    setSelectedPlace(data);
    setPhysicals(
      physicalData.filter((physical) => physical.place === data)[0].data
    );
  };

  const weekCheckboxHandler = (data, index) => {
    setDays((days) =>
      days.map((day, currentIndex) =>
        currentIndex === index ? { ...day, checked: data } : day
      )
    );
  };

  const physicalCheckboxHandler = (data, index) => {
    setPhysicals((physicals) =>
      physicals.map((physical, currentIndex) =>
        currentIndex === index ? { ...physical, checked: data } : physical
      )
    );
  };

  const serviceCheckboxHandler = (data, index) => {
    setServices((services) =>
      services.map((service, currentIndex) =>
        currentIndex === index ? { ...service, checked: data } : service
      )
    );
  };

  const changeEnteredNum = (num) => {
    const value = num;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setAmount(removedCommaValue.toLocaleString());
  };

  const changeEnteredNum2 = (num) => {
    const value = num;
    const removedCommaValue = Number(value.replaceAll(",", ""));
    setPartnerAmount(removedCommaValue.toLocaleString());
  };

  const getContractInfo = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getContractInfo,
      requestConfig: {
        user_id: userInfo.user_id,
      },
    })
      .then((res) => {
        if (res.success) {
          let info = res.contractInfo;
          if (info === undefined) {
            info = {};
            info.user_id = userInfo.user_id;
            info.user_name = userInfo.user_name;
            info.user_tel = userInfo.user_tel;
            info.company_name = userInfo.company_name;
            info.company_number = userInfo.company_number;
            info.company_addr1 = userInfo.company_addr1;
            info.company_addr2 = userInfo.company_addr2;
            info.contract_start_date = new Date();
            info.contract_end_date = new Date();
          }
          setContractInfo(info);
          setStartDate(new Date(info.contract_start_date));
          setEndDate(new Date(info.contract_end_date));
          changeEnteredNum(info.contract_amount);
          setPaymentDate(info.contract_payment_date);

          info.contract_days?.split(",").map((data, index) => {
            if (data === "1") weekCheckboxHandler(true, index);
          });

          setPartnerCompanyName(info.partner_company_name);
          setPartnerCompanyNumber(info.partner_company_number);
          setPartnerName(info.partner_name);
          setPartnerTel(info.partner_tel);
          setPartnerAmount(info.partner_amount);

          setManagerName(info.manager_name);
          setManagerTel(info.manager_tel);

          setContractTotalTime(info.contract_total_time);
          selectedPlaceHandler(info.contract_place);
          info.contract_physicals?.split(",").map((data, index) => {
            if (data === "1") physicalCheckboxHandler(true, index);
          });

          info.contract_services?.split(",").map((data, index) => {
            if (data === "1") serviceCheckboxHandler(true, index);
          });

          setComment(info.contract_comment);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getContractInfo : ", err);
      });
  };

  const getPartnerInfo = () => {
    if (partnerCompanyName?.length === 0) {
      alert("사업자명을 적어주세요.");
      return;
    }

    axiosFetchA({
      method: "post",
      url: apiUrls.getPartnerInfo,
      requestConfig: {
        company_name: partnerCompanyName,
      },
    })
      .then((res) => {
        if (res.success) {
          if (res.partnerInfo === undefined) {
            alert("협력업체 검색 결과가 없습니다.");
          } else {
            setPartnerCompanyName(res.partnerInfo.company_name);
            setPartnerCompanyNumber(res.partnerInfo.company_number);
            setPartnerName(res.partnerInfo.user_name);
            setPartnerTel(res.partnerInfo.user_tel);
          }
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getPartnerInfo : ", err);
      });
  };

  const saveContract = () => {
    let dayStr = "";
    let dayName = "";
    days.map((day) => {
      if (day.checked) {
        dayStr += "1,";
        dayName += day.name + ",";
      } else dayStr += "0,";
    });
    let physicalStr = "";
    let physicalName = "";
    physicals.map((physical) => {
      if (physical.checked) {
        physicalStr += "1,";
        physicalName += physical.name + ",";
      } else physicalStr += "0,";
    });
    let serviceStr = "";
    let serviceName = "";
    services.map((service) => {
      if (service.checked) {
        serviceStr += "1,";
        serviceName += service.name + ",";
      } else serviceStr += "0,";
    });

    if (!contractInfo.user_id) contractInfo.user_id = userInfo.user_id;
    if (!contractInfo.user_name) contractInfo.user_name = userInfo.user_name;
    if (!contractInfo.user_tel) contractInfo.user_tel = userInfo.user_tel;
    if (!contractInfo.company_name)
      contractInfo.company_name = userInfo.company_name;
    if (!contractInfo.company_number)
      contractInfo.company_number = userInfo.company_number;
    if (!contractInfo.company_addr1)
      contractInfo.company_addr1 = userInfo.company_addr1;
    if (!contractInfo.company_addr2)
      contractInfo.company_addr2 = userInfo.company_addr2;

    contractInfo.contract_start_date = startDate;
    contractInfo.contract_end_date = endDate;
    contractInfo.contract_amount = amount;
    contractInfo.contract_payment_date = paymentDate;
    contractInfo.contract_days = dayStr.substring(0, dayStr.length - 1);
    contractInfo.contract_day_name = dayName.substring(0, dayName.length - 1);
    contractInfo.partner_name = partnerName;
    contractInfo.partner_tel = partnerTel;
    contractInfo.partner_company_name = partnerCompanyName;
    contractInfo.partner_company_number = partnerCompanyNumber;
    contractInfo.partner_amount = partnerAmount;
    contractInfo.manager_name = managerName;
    contractInfo.manager_tel = managerTel;
    contractInfo.contract_total_time = contractTotalTime;
    contractInfo.contract_place = selectedPlace;
    contractInfo.contract_physicals = physicalStr.substring(
      0,
      physicalStr.length - 1
    );
    contractInfo.contract_physical_name = physicalName.substring(
      0,
      physicalName.length - 1
    );

    contractInfo.contract_services = serviceStr.substring(
      0,
      serviceStr.length - 1
    );
    contractInfo.contract_service_name = serviceName.substring(
      0,
      serviceName.length - 1
    );

    contractInfo.contract_comment = comment;

    axiosFetchA({
      method: "post",
      url: apiUrls.addContractInfo,
      requestConfig: {
        contractInfo: contractInfo,
      },
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          closeModal();
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] addContractInfo : ", err);
      });
  };

  useEffect(() => {
    getContractInfo();
  }, []);

  return (
    <div className="admin-dialog dialog-wrap" id="modal_admin_detail">
      <div className="dialog-box admin-dialog-box contract">
        <div className="admin-dialog-area">
          <div className="title-area">
            <h2>계약서</h2>
          </div>
          <div className="dialog-content">
            <div className="admin-dialog-item complex">
              <h3 className="adi-title">고객사</h3>
              <div className="adi-content">
                <div className="admin-dialog-item">
                  <h3 className="adi-title">회사명</h3>
                  <div className="adi-content">
                    {contractInfo?.company_name}
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h3 className="adi-title">주소</h3>
                  <div className="adi-content">
                    {contractInfo?.company_addr1} {contractInfo?.company_addr2}
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">담당자</h4>
                  <div className="adi-content">{contractInfo?.user_name}</div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">연락처</h4>
                  <div className="adi-content">{contractInfo?.user_tel}</div>
                </div>
              </div>
            </div>
            <div className="admin-dialog-item complex">
              <h3 className="adi-title">계약정보</h3>
              <div className="adi-content">
                <div className="admin-dialog-item">
                  <h4 className="adi-title">계약시작일</h4>
                  <div className="adi-content">
                    <DatePickerComponent
                      selected={startDate}
                      onChange={setStartDate}
                      withPortal={false}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">계약종료일</h4>
                  <div className="adi-content">
                    <DatePickerComponent
                      selected={endDate}
                      onChange={setEndDate}
                      withPortal={false}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">계약금액(월)</h4>
                  <div className="adi-content">
                    <input
                      className="input-admin"
                      type="text"
                      value={amount}
                      onChange={(e) => {
                        changeEnteredNum(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">결제일</h4>
                  <div className="adi-content">
                    <input
                      className="input-admin"
                      type="number"
                      id="contract_payment_date"
                      value={paymentDate}
                      maxLength="2"
                      min="1"
                      max="31"
                      onChange={(e) => {
                        setPaymentDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">방문요일</h4>
                  <div className="adi-content">
                    <ul className="adi-item-row">
                      {days?.map((item, index) => (
                        <li>
                          <div className="input-check-zim">
                            <input
                              key={item.name}
                              type="checkbox"
                              id={`inp1${index}`}
                              checked={item.checked}
                              onChange={(e) => {
                                weekCheckboxHandler(e.target.checked, index);
                              }}
                            />
                            <label htmlFor={`inp1${index}`}>{item.name}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-dialog-item complex">
              <h3 className="adi-title">협력사</h3>
              <div className="adi-content">
                <div className="admin-dialog-item">
                  <h4 className="adi-title">회사명</h4>
                  <div className="adi-content">
                    <input
                      type="text"
                      className="input-admin"
                      value={partnerCompanyName}
                      onChange={(e) => {
                        setPartnerCompanyName(e.target.value);
                      }}
                    />
                    <button type="button" onClick={getPartnerInfo}>
                      검색
                    </button>
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">사업자번호</h4>
                  <div className="adi-content">{partnerCompanyNumber}</div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">담당자</h4>
                  <div className="adi-content">
                    <input
                      type="text"
                      className="input-admin"
                      value={partnerName}
                      onChange={(e) => {
                        setPartnerName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">연락처</h4>
                  <div className="adi-content">
                    <input
                      type="text"
                      className="input-admin"
                      value={partnerTel}
                      onChange={(e) => {
                        setPartnerTel(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">협력사 계약금액(월)</h4>
                  <div className="adi-content">
                    <input
                      type="text"
                      className="input-admin"
                      value={partnerAmount}
                      onChange={(e) => {
                        changeEnteredNum2(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-dialog-item complex">
              <h3 className="adi-title">본사</h3>
              <div className="adi-content">
                <div className="admin-dialog-item">
                  <h4 className="adi-title">담당자</h4>
                  <div className="adi-content">
                    <input
                      type="text"
                      className="input-admin"
                      value={managerName}
                      onChange={(e) => {
                        setManagerName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">연락처</h4>
                  <div className="adi-content">
                    <input
                      type="text"
                      className="input-admin"
                      value={managerTel}
                      onChange={(e) => {
                        setManagerTel(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">일 소요 분</h3>
              <div className="adi-content">
                <input
                  type="number"
                  className="input-admin"
                  value={contractTotalTime}
                  onChange={(e) => {
                    setContractTotalTime(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">사업장구분</h3>
              <div className="adi-content">
                <Select
                  className="admin-select adi-company-category"
                  options={selectPlaceOptions}
                  value={selectPlaceOptions.filter(function (option) {
                    return option.label === selectedPlace;
                  })}
                  onChange={(e) => selectedPlaceHandler(e.label)}
                ></Select>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">물리적 범위</h3>
              <div className="adi-content">
                <ul className="adi-item-row">
                  {physicals?.map((item, index) => (
                    <li>
                      <div className="input-check-zim">
                        <input
                          key={item.name}
                          type="checkbox"
                          id={`inppc0${index}`}
                          checked={item.checked}
                          onChange={(e) => {
                            physicalCheckboxHandler(e.target.checked, index);
                          }}
                        />
                        <label for={`inppc0${index}`}>{item.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">상세 서비스 항목</h3>
              <div className="adi-content">
                <ul className="adi-item-row">
                  {services?.map((item, index) => (
                    <li>
                      <div className="input-check-zim">
                        <input
                          key={item.name}
                          type="checkbox"
                          id={`inps0${index}`}
                          checked={item.checked}
                          onChange={(e) => {
                            serviceCheckboxHandler(e.target.checked, index);
                          }}
                        />
                        <label htmlFor={`inps0${index}`}>{item.name}</label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">
                기타사항
                <br />
                (구두서술 필요부분)
              </h3>
              <div className="adi-content">
                <textarea
                  className="textarea"
                  id=""
                  cols="30"
                  rows="10"
                  defaultValue={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="btn-area">
              <button type="button" onClick={saveContract}>
                저장
              </button>
              <button type="button" onClick={closeModal}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractInfo;
