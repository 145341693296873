import React from "react";

const CoreValuePage = () => {
  return (
    <div className="container">
      <section className="visual-top value">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>클리니션의 공간컨설팅은 클리닝서비스의 시작입니다</p>
            <h2>VALUE</h2>
          </div>
        </div>
      </section>
      <section className="value-section-consulting">
        <div className="inner">
          <div className="text-area">
            <h2>공간컨설팅</h2>
            <div className="ment">
              클리니션은 업계 최초로 공간컨설팅 결과를 고객에게 전달합니다.
              <br />
              우리 회사 공간의 현황 분석을 받아보세요.
            </div>
            <p>
              어떤 서비스를 어떻게 받아야 할지 고민은 이제 그만! 클리니션의
              공간컨설팅을 받아보세요. 우리 회사의 현황을 다각적으로 분석한
              결과를 제공합니다.
            </p>
          </div>
          <div className="consulting-list">
            <ul>
              <li>
                <i className="icon icon-1">
                  <img
                    src="../assets/images/value/icon_consulting_01.png"
                    alt=""
                  />
                </i>
                <h3>정량적 수치 분석</h3>
                <p>
                  고객현장을 수치적으로 접근합니다.
                  <br />
                  분석 카테고리를 기준으로 고객 환경을 복합적으로 평가합니다.
                </p>
              </li>
              <li>
                <i className="icon icon-2">
                  <img
                    src="../assets/images/value/icon_consulting_02.png"
                    alt=""
                  />
                </i>
                <h3>전문가의 정성적 분석</h3>
                <p>
                  다양한 노하우를 갖고 있는 현장 매니저가 공간별로 오염도를
                  체크하여 종합적 판단을 내립니다.
                </p>
              </li>
              <li>
                <i className="icon icon-3">
                  <img
                    src="../assets/images/value/icon_consulting_03.png"
                    alt=""
                  />
                </i>
                <h3>고객의 요구사항</h3>
                <p>
                  현재 근무하고 계시는 담당자분의 요구 사항을 정확하게
                  반영합니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="value-section-service">
        <div className="inner">
          <h2>서비스 디자인</h2>
          <div className="img-area"></div>
          <div className="text-area">
            <div className="ment">
              최적화된 서비스 디자인으로 효율적인 비용구조를 실현합니다.
            </div>
            <ul className="service-list">
              <li>
                <h3>
                  효율적인 <span>비용구조</span>
                </h3>
                <p>
                  낭비되는 비용 구조를 분석하여 비용 통제를 강화합니다.
                  <br />
                  클리니션은 우리 회사의 클리닝 서비스와 비용을 최적으로
                  만들어줍니다.
                </p>
              </li>
              <li>
                <h3>
                  컨설팅 기반의 <span>최적화 서비스 디자인</span>
                </h3>
                <p>
                  공간컨설팅을 기반으로 최적화된 서비스 디자인을 받아볼 수
                  있습니다.
                </p>
              </li>
              <li>
                <h3>
                  <span>공간컨설팅</span>
                </h3>
                <p>
                  어떤 서비스를 받아야 할지 고민은 그만, 우리 회사의 현황을
                  다각적으로 분석한 결과로 컨설팅합니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="value-section-system">
        <div className="inner">
          <div className="text-area">
            <h2>
              서비스
              <br />
              운영시스템(ZIM)
            </h2>
            <div className="ment">
              클리니션 서비스의 완성은 시스템 기반의 체계적인 운영관리 입니다.
            </div>
            <p>
              우리가 지불한 비용으로 서비스가 제대로 진행되고 있는지 확인 되어야
              합니다. 실시간으로 현재 우리 회사의 서비스 현황을 확인할 수 있어
              편리하게 관리할 수 있습니다. 클리닝 서비스 품질을 수치화하여
              지속적으로 제공 받을 수 있습니다.
              <br />
              고객은 시스템 접속을 통해서 서비스 내용을 편리하게 확인할 수
              있으며 수치화 된 서비스 품질을 효율적으로 관리 가능합니다.
            </p>
          </div>
          <div className="img-area">
            <div className="img-box">
              <img src="../assets/images/value/service_bg.png" alt="" />
            </div>
            <div className="text-box">
              <div className="title">클리니션의 운영관리</div>
              <div className="ment">
                현장의 담당 서비스팀이 서비스 이행 내용을 직접 체크하여 DB화
                시킵니다.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoreValuePage;
