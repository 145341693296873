export const apiUrls = {
  login: "user/userLogin",
  signup: "user/userSignUp",
  reqId: "user/userReqId",
  reqPw: "user/userReqPw",
  updatePw: "user/userUpdatePw",

  getBusinessList: "board/getBusinessList",
  getBusinessDetail: (postId = 1) => `board/${postId}/businessDetail`,
  deleteBusiness: "board/deleteBusiness",
  writeBusiness: "board/writeBusiness",
  updateBusiness: "board/updateBusiness",
  saveWriteImg: "board/saveWriteImg",

  getAllReportList: "admin/getAllReportList",
  getUserList: "admin/getUserList",
  updateUserList: "admin/updateUserList",
  getContractInfo: "admin/getContractInfo",
  getContractList: "admin/getContractList",
  getServiceList: "admin/getServiceList",
  getPartnerInfo: "admin/getPartnerInfo",
  addContractInfo: "admin/addContractInfo",
  getFeedbackList: "admin/getFeedbackList",

  getContractInfoU: "user/getContractInfo",
  getServiceListU: "user/getServiceListU",
  getContractCheckListInfo: "user/getContractCheckListInfo",
  getCheckList: "user/getCheckList",
  addCheckList: "user/addCheckList",
  delCheckList: "user/delCheckList",
  updateCheckList: "user/updateCheckList",
  updateCommentCheckList: "user/updateCommentCheckList",
  confirmID: "user/confirmID",
  addFeedbackInfo: "user/addFeedbackInfo",
  getCustomerList: "user/getCustomerList",

  aTokenCheck: "token/aTokenCheck",
  rTokenCheck: "token/rTokenCheck",

  getNiceInit: "nice/getNiceInfo",
  getNiceDecodeData: "nice/getNiceDecodeData",

  sendEmail: "etc/sendEmail",

  // join: "user/join",
  // tokenCheck: "auth/tokenCheck",
  // sendEmail: "help/sendEmail",
  // getReferenceList: "board/getReferenceList",
  // getReferenceListMain: "board/getReferenceListMain",
  // getReferenceDetail: (postId = 1) => `board/${postId}/referenceDetail`,
  // deleteReference: "board/deleteReference",
  // writeReference: "board/writeReference",
  // updateReference: "board/updateReference",
  // getTrendList: "board/getTrendList",
  // getTrendDetail: (postId = 1) => `board/${postId}/trendDetail`,
  // deleteTrend: "board/deleteTrend",
  // writeTrend: "board/writeTrend",
  // updateTrend: "board/updateTrend",
  // checkPw: "board/checkPw",
  // getHistory: "history/getHistory",
  // saveWriteImg: "board/saveWriteImg",
};
