import React, { useState } from "react";

const RegularClinicPage = () => {
  const [radiobtn, setRadioBtn] = useState("r1");

  const radioBtnClick = (e, btn) => {
    e.preventDefault();
    setRadioBtn(btn);
  };

  return (
    <div className="container">
      <section className="visual-top cleaning">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>
              정기클리닝 서비스는 공간컨설팅과 서비스 시스템을 기반으로
              제공합니다
            </p>
            <h2>정기클리닝</h2>
          </div>
        </div>
      </section>
      <section className="cleaning-section-needs">
        <div className="inner">
          <div className="text-area">
            <h2>정기클리닝 서비스의 새로운 트렌드를 개척하다</h2>
            <p className="ment">
              클리니션의 정기 클리닝 서비스는 지속적인 서비스 품질 강화를
              진행합니다.
              <br />
              서비스 운영 중에도 고객과의 커뮤니케이션으로 최적화 서비스를 위한
              활동을 제공합니다.
            </p>
          </div>
          <div className="img-box type1">
            <div className="img-box-left">
              <span>**기업 최과장님의 고민</span>
              <p>
                부장님께서 정기클리닝을 알아보라고 하시는데…
                <br />
                도대체 우리 회사에 필요한 정기 클리닝은 어떤걸까?
                <br />
                어디서부터 시작해야 할 지 모르겠는데…
              </p>
              <img src="../assets/images/cleaning/person_01.png" alt="" />
            </div>
            <div className="img-box-right">
              <span>**디자인 김대리님의 고민</span>
              <p>
                정기클리닝을 받고 있는데, 우리가 내고 있는 비용으로 <br />
                서비스가 잘 되고 있는지 알 수가 없네. <br />
                업체 사장님은 열심히 하고 있다고는 하는데…
              </p>
              <img src="../assets/images/cleaning/person_02.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="cleaning-section-circle">
        <div className="inner">
          <div className="text-area">
            <h2>클리니션의 공간컨설팅은 클리닝 서비스의 시작입니다.</h2>
            <p className="ment">
              어떤 서비스를 어떻게 받아야 할지 고민은 이제 그만!
              <br />
              클리니션의 공간컨설팅을 받아보세요. 우리 회사의 현황을 다각적으로
              분석한 결과를 제공합니다.
            </p>
          </div>
          <div className="img-area type1">
            <img src="../assets/images/cleaning/cleaning_circle.png" alt="" />
          </div>
          <div className="cleaning-circle-list">
            <ul>
              <li>
                <span>01</span>
                <h3>정량적 수치분석</h3>
                <p>
                  고객현장을 수치적으로 접근합니다. <br />
                  분석 카테고리를 기준으로 고객 환경을 <br />
                  복합적으로 평가합니다.
                </p>
              </li>
              <li>
                <span>02</span>
                <h3>전문가의 정성적 분석</h3>
                <p>
                  고객현장을 수치적으로 다양한 노하우를 갖고 있는 현장 매니저가
                  공간별로 오염도를 체크하여종합적 판단을 내립니다. 분석
                  카테고리를 기준으로 고객 환경을 복합적으로 평가합니다.
                </p>
              </li>
              <li>
                <span>03</span>
                <h3>고객의 요구사항</h3>
                <p>
                  현재 근무하고 계시는 담당자 분의 요구 사항을 정확하게
                  반영합니다.
                </p>
              </li>
              <li>
                <span>Result</span>
                <h3>현황 분석 보고</h3>
                <p>
                  클리니션은 업계 최초로 공간컨설팅 결과를 고객에게 전달합니다.
                  우리 회사 공간의 현황 분석을 받아보세요.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="value-section-service">
        <div className="inner">
          <h2>서비스 디자인</h2>
          <div className="img-area"></div>
          <div className="text-area">
            <div className="ment">
              최적화된 서비스 디자인으로 효율적인 비용구조를 실현합니다.
            </div>
            <ul className="service-list">
              <li>
                <h3>
                  효율적인 <span>비용구조</span>
                </h3>
                <p>
                  낭비되는 비용 구조를 분석하여 비용 통제를 강화합니다.
                  <br />
                  클리니션은 우리 회사의 클리닝 서비스와 비용을 최적으로
                  만들어줍니다.
                </p>
              </li>
              <li>
                <h3>
                  컨설팅 기반의 <span>최적화 서비스 디자인</span>
                </h3>
                <p>
                  공간컨설팅을 기반으로 최적화된 서비스 디자인을 받아볼 수
                  있습니다.
                </p>
              </li>
              <li>
                <h3>
                  <span>공간컨설팅</span>
                </h3>
                <p>
                  어떤 서비스를 받아야 할지 고민은 그만, 우리 회사의 현황을
                  다각적으로 분석한 컨설팅을 제공합니다.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="cleaning-section-system">
        <div className="inner">
          <div className="text-area">
            <h2>
              클리니션 서비스의 완성은
              <br />
              시스템 기반의 체계적 운영관리입니다.
            </h2>
            <p className="ment">
              우리가 지불한 비용으로 서비스가 제대로 진행되고 있는 지 확인
              되어야 합니다.
              <br />
              실시간으로 현재 우리회사의 서비스 현황을 확인할 수 있어 편리하게
              관리 할 수 있습니다.
              <br />
              클리닝 서비스의 품질을 수치화하여 지속적으로 제공받을 수 있습니다.
            </p>
          </div>
          <div className="cleaning-system-list">
            <ul>
              <li className="cleaning-system-item item1">
                <span>
                  현장의 담당 서비스팀이 서비스 이행 내용을 직접 체크해서 DB화
                  시킵니다.
                </span>
              </li>
              <li className="cleaning-system-item item2">
                <span>
                  고객의 만족도를 지속적으로 모니터링하여 서비스 운영에
                  반영합니다.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="cleaning-section-feature">
        <div className="inner">
          <div className="text-area">
            <h2>정기클리닝 서비스</h2>
          </div>
          <div className="cleaning-feature-list">
            <ul>
              <li className="cleaning-feature-item active">
                <div className="title">
                  <h3>01 제공 서비스 상세</h3>
                </div>
                <div className="content">
                  <div className="service-detail">
                    <ul>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail01.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">ZIM</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail02.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">바닥관리</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail03.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">유리창관리</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail04.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">창틀먼지제거</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail05.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">출입문관리</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail06.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">쓰레기배출</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail07.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">분리수거</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail08.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">테이블먼지제거</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail09.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">집기클리닝</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail10.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">변기세정</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail11.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">세면대세정</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail12.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">비품관리</div>
                      </li>
                      <li className="service-detail-list">
                        <div className="ico-area">
                          <img
                            src="/assets/images/cleaning/icon_service_detail13.png"
                            alt="sssss"
                          />
                        </div>
                        <div className="text-area">상주인원파견</div>
                      </li>
                    </ul>
                    {/* ZIM | 바닥 관리 | 유리창 관리 | 창틀 먼지제거 | 출입문 관리
                    | 쓰레기 배출 | 분리수거 | 테이블 먼지제거 | 집기 클리닝 |
                    변기 세정 | 세면대 세정 | 비품관리 | 상주 인원 파견 */}
                  </div>
                </div>
              </li>
              <li className="cleaning-feature-item active">
                <div className="title">
                  <h3>02 서비스 대상</h3>
                </div>
                <div className="content">
                  <ul className="service-target-list">
                    <li
                      className={
                        radiobtn === "r1"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r1");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target01.svg"
                            alt=""
                          />
                        </i>
                        <span>건물</span>
                      </button>
                      <div className="target-box" data-index="0">
                        <h4>건물</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star_half.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 2~3회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                안내데스크 | 로비 | 복도 | 계단 | 휴게실 |
                                화장실 | 엘리베이터 | 테라스 | 주차장 | 흡연구역
                                | 건물주변 | 창고 | 기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li
                      className={
                        radiobtn === "r2"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r2");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target02.svg"
                            alt=""
                          />
                        </i>
                        <span>사무실</span>
                      </button>
                      <div className="target-box" data-index="1">
                        <h4>사무실</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star_half.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 2~3회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                사무공간 | 회의실 | 탕비실 | 창고 | 임원실 |
                                화장실 | 식당 | 기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li
                      className={
                        radiobtn === "r3"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r3");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target03.svg"
                            alt=""
                          />
                        </i>
                        <span>상업시설</span>
                      </button>
                      <div className="target-box" data-index="2">
                        <h4>상업시설</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 5회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                매장 | 계산대 | 주방 | 회의룸 | 직원공간 |
                                키즈룸 | 화장실 | 흡연실 | 창고 | 기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li
                      className={
                        radiobtn === "r4"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r4");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target04.svg"
                            alt=""
                          />
                        </i>
                        <span>의료시설</span>
                      </button>
                      <div className="target-box" data-index="3">
                        <h4>의료시설</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 5회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                안내데스크 | 대기실 | 상담실 | 진료실 | 회복실 |
                                검사실 | 입원실 | 창고 | 엘리베이터 | 직원공간 |
                                화장실 | 기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li
                      className={
                        radiobtn === "r5"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r5");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target05.svg"
                            alt=""
                          />
                        </i>
                        <span>교육시설</span>
                      </button>
                      <div className="target-box" data-index="4">
                        <h4>교육시설</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 5회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                안내데스크 | 로비 | 강의실 | 교무실 | 독서실 |
                                창고 | 탕비실 | 화장실 | 기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li
                      className={
                        radiobtn === "r6"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r6");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target06.svg"
                            alt=""
                          />
                        </i>
                        <span>주거시설</span>
                      </button>
                      <div className="target-box" data-index="5">
                        <h4>주거시설</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star_half.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 2~3회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                안내데스크 | 로비 | 강의실 | 교무실 | 독서실 |
                                창고 | 탕비실 | 화장실 | 기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>

                    <li
                      className={
                        radiobtn === "r7"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r7");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target08.svg"
                            alt=""
                          />
                        </i>
                        <span>운동시설</span>
                      </button>
                      <div className="target-box" data-index="6">
                        <h4>운동시설</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star_clear.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 2~3회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                안내데스크 | 운동공간 | 직원공간 | 운동기구 |
                                탈의실 | 샤워실 | 창고 | 화장실 | 기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li
                      className={
                        radiobtn === "r8"
                          ? "service-target-item active"
                          : "service-target-item "
                      }
                    >
                      <button
                        className="icon-area"
                        onClick={(e) => {
                          radioBtnClick(e, "r8");
                        }}
                      >
                        <i>
                          <img
                            src="../assets/images/cleaning/icon_target07.svg"
                            alt=""
                          />
                        </i>
                        <span>종교시설</span>
                      </button>
                      <div className="target-box" data-index="7">
                        <h4>종교시설</h4>
                        <table>
                          <colgroup>
                            <col width="120px" />
                            <col />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>중요도</th>
                              <td>
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star.png"
                                  alt=""
                                />
                                <img
                                  src="/assets/images/cleaning/star_clear.png"
                                  alt=""
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>권장주기</th>
                              <td>주 2회</td>
                            </tr>
                            <tr>
                              <th>물리적 범위</th>
                              <td>
                                예배당 | 회의실 | 교육실 | 직원공간 | 창고 |
                                엘리베이터 | 화장실 | 계단 | 복도 | 건물주변 |
                                기타공간
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegularClinicPage;
