import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { feedbackListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import FeeedbackInfo from "components/popup/feedbackInfo";
import moment from "moment";

const FeedbackListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [feedbackList, setFeedbackList] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState();
  const [userListPage, setUserListPage] = useRecoilState(
    feedbackListPageIndexState
  );

  const openModal = (e, data) => {
    e.preventDefault();
    setModalOpen(true);
    setSelectedFeedback(data);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getFeedbackList = (reqData) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getFeedbackList,
      requestConfig: { currentNavPage: reqData },
    })
      .then((res) => {
        if (res.success) {
          setUserResult(res.result);
          setFeedbackList(res.feedbackList);
          setPageList(res.pageList);
          setUserListPage(reqData);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getFeedbackList : ", err);
      });
  };

  const formHandleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getFeedbackList(userListPage);
  }, []);

  return (
    <>
      <div className="board-list-content">
        <div className="board-head">
          <div className="board-search">
            <form onSubmit={formHandleSubmit}>
              <fieldset className="">
                <label className="search-keyword">
                  <input
                    type="text"
                    id="searchData"
                    placeholder="검색어를 입력해주세요"
                    name="searchData"
                  />
                </label>
                <button
                  id="btn-search"
                  type="button"
                  className="btn btn-search"
                >
                  검색
                </button>
              </fieldset>
            </form>
          </div>
        </div>

        <div className="board-body board-table" id="idTable">
          <div className="blc-head">
            <ul className="blc-tr">
              <li className="w10">
                <strong>아이디</strong>
              </li>
              <li className="w15">
                <strong>회사명</strong>
              </li>
              <li className="w10">
                <strong>받은날짜</strong>
              </li>
              <li className="w15">
                <strong>사업자번호</strong>
              </li>
              <li className="w10">
                <strong>담당자</strong>
              </li>
              <li className="w15">
                <strong>연락처</strong>
              </li>
              <li className="w15">
                <strong>이메일</strong>
              </li>
              <li className="w10">
                <strong>피드백</strong>
              </li>
            </ul>
          </div>
          <div className="blc-body">
            <ul>
              {feedbackList?.map((item) => (
                <li key={item.user_id}>
                  <ul className="blc-tr" data-id="<%= item.customer_id %>">
                    <li className="ad_m w10">{item.user_id}</li>
                    <li className="ad_m w15">{item.company_name}</li>
                    <li className="ad_m w10">
                      {moment(item.signup_date).format("YYYY-MM-DD")}
                    </li>
                    <li className="ad_m w15">{item.company_number}</li>
                    <li className="ad_m w10">{item.user_name}</li>
                    <li className="ad_m w15">{item.user_tel}</li>
                    <li className="ad_m w15">{item.user_email}</li>
                    <li className="ad_m w10">
                      <button
                        id="btn-search"
                        type="button"
                        className="sellerBtn"
                        onClick={(e) => {
                          openModal(e, item);
                        }}
                      >
                        <img src="/assets/images/admin/icon_more.png" alt="" />
                      </button>
                    </li>
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="board-footer">
          <div className="blc-pagenation">
            <Link
              onClick={() =>
                getFeedbackList(
                  userResult.currentPage - 10 < 1
                    ? 1
                    : userResult.currentPage - 10
                )
              }
              className="blc-first icon-page-first"
            >
              <span className="skip">처음</span>
            </Link>
            <Link
              className="blc-prev icon-page-prev"
              onClick={() =>
                getFeedbackList(
                  userResult.currentPage - 10 < 1
                    ? 1
                    : userResult.currentPage - 10
                )
              }
            >
              <span className="skip">이전</span>
            </Link>
            <ul className="blc-pages">
              {pageList?.map((item) => (
                <li
                  key={item}
                  className={item === userResult.currentPage ? "active" : null}
                >
                  <Link onClick={() => getFeedbackList(item)}>{item}</Link>
                </li>
              ))}
            </ul>
            <Link
              className="blc-next icon-page-next"
              onClick={() =>
                getFeedbackList(
                  pageList[pageList.length - 1] + 1 < userResult.maxPage
                    ? pageList[pageList.length - 1] + 1
                    : pageList[pageList.length - 1]
                )
              }
            >
              <span className="skip">다음</span>
            </Link>
            <Link
              className="blc-last icon-page-last"
              onClick={() =>
                getFeedbackList(
                  pageList[pageList.length - 1] + 1 < userResult.maxPage
                    ? pageList[pageList.length - 1] + 1
                    : pageList[pageList.length - 1]
                )
              }
            >
              <span className="skip">맨끝</span>
            </Link>
          </div>
        </div>
      </div>
      {modalOpen ? (
        <FeeedbackInfo closeModal={closeModal} feedback={selectedFeedback} />
      ) : null}
    </>
  );
};

export default FeedbackListPage;
