import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

// const data = [
//   {
//     name: "5.1",
//     시작: 6.3,
//     종료: 7.2,
//   },
//   {
//     name: "5.3",
//     시작: 6.15,
//     종료: 7.5,
//   },
//   {
//     name: "5.5",
//     시작: 5.2,
//     종료: 6.5,
//   },
//   {
//     name: "5.8",
//     시작: 4.05,
//     종료: 5.5,
//   },
//   {
//     name: "5.10",
//     시작: 6.5,
//     종료: 7.4,
//   },
//   {
//     name: "5.12",
//     시작: 8.03,
//     종료: 9.5,
//   },
//   {
//     name: "5.15",
//     시작: 5.44,
//     종료: 7.05,
//   },
//   {
//     name: "5.1",
//     시작: 6.3,
//     종료: 7.2,
//   },
//   {
//     name: "5.3",
//     시작: 6.15,
//     종료: 7.5,
//   },
//   {
//     name: "5.5",
//     시작: 5.2,
//     종료: 6.5,
//   },
//   {
//     name: "5.8",
//     시작: 4.05,
//     종료: 5.5,
//   },
//   {
//     name: "5.10",
//     시작: 6.5,
//     종료: 7.4,
//   },
//   {
//     name: "5.12",
//     시작: 8.03,
//     종료: 9.5,
//   },
//   {
//     name: "5.15",
//     시작: 5.44,
//     종료: 7.05,
//   },
//   {
//     name: "5.1",
//     시작: 6.3,
//     종료: 7.2,
//   },
//   {
//     name: "5.3",
//     시작: 6.15,
//     종료: 7.5,
//   },
//   {
//     name: "5.5",
//     시작: 5.2,
//     종료: 6.5,
//   },
//   {
//     name: "5.8",
//     시작: 4.05,
//     종료: 5.5,
//   },
//   {
//     name: "5.10",
//     시작: 6.5,
//     종료: 7.4,
//   },
//   {
//     name: "5.12",
//     시작: 8.03,
//     종료: 9.5,
//   },
//   {
//     name: "5.15",
//     시작: 5.44,
//     종료: 7.05,
//   },
//   {
//     name: "5.1",
//     시작: 6.3,
//     종료: 7.2,
//   },
//   {
//     name: "5.3",
//     시작: 6.15,
//     종료: 7.5,
//   },
//   {
//     name: "5.5",
//     시작: 5.2,
//     종료: 6.5,
//   },
//   {
//     name: "5.8",
//     시작: 4.05,
//     종료: 5.5,
//   },
//   {
//     name: "5.10",
//     시작: 6.5,
//     종료: 7.4,
//   },
//   {
//     name: "5.12",
//     시작: 8.03,
//     종료: 9.5,
//   },
//   {
//     name: "5.15",
//     시작: 5.44,
//     종료: 7.05,
//   },
// ];

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={20} y={0} dy={16} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5} textAnchor="end" stroke="#999999">
        {payload.value}시
      </text>
    </g>
  );
};

const LineChartWork = (props) => {
  const data = props.data;
  return (
    <ResponsiveContainer height="100%" width="100%" aspect={1.3}>
      <LineChart
        data={data}
        margin={{
          top: 50,
          right: 10,
          left: -10,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
        <YAxis tick={<CustomizedYAxisTick />} />
        <Tooltip />
        <Legend />
        <Line type="linear" dataKey="시작" stroke="#29ABE2" />
        <Line type="linear" dataKey="종료" stroke="#623189" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartWork;
