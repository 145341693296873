import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const cx = 120;
const cy = 100;
const iR = 60;
const oR = 100;

const PieChartHalf = (props) => {
  const value = parseInt(props.count);
  const totalCount = parseInt(props.totalCount);
  const realValue = (value / totalCount) * 100;

  const data = [
    { name: "A", value: realValue, color: "#02AEED" },
    { name: "B", value: 1, color: "#FFFFFF" },
    // { name: "C", value: 100 - realValue, color: "#FF0000" },
    { name: "C", value: 100 - realValue, color: "#EEEEEE" },
  ];

  return (
    <div className="pie">
      <PieChart width={250} height={130}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#BD0304"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      <div className="pie-text-area">
        <div className="pie-title">월간서비스진행</div>
        <div className="pie-data">
          {value} / {totalCount}
        </div>
      </div>
    </div>
  );
};

export default PieChartHalf;
