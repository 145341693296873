import React from "react";
import { Link } from "react-router-dom";

const goTop = (e) => {
  e.preventDefault();
  window.scrollTo(0, 0);
};

const FloatingBtn = () => {
  return (
    <aside className="floating-menu">
      <ul>
        <li className="floating-menu-item1">
          <a
            href="http://pf.kakao.com/_QVNQxj/chat"
            target="_blank"
            rel="noreferrer"
          >
            <i>
              <img
                src="../assets/images/common/ico_floating_navi01.png"
                alt=""
              />
            </i>
            <span>카카오톡 상담</span>
          </a>
        </li>
        <li className="floating-menu-item2">
          <a href="tel:01031019441">
            <i>
              <img
                src="../assets/images/common/ico_floating_navi02.png"
                alt=""
              />
            </i>
            <span>전화 상담</span>
          </a>
        </li>
        <li className="floating-menu-item3">
          <Link to="/reqconsulting" className="btn-navi depth1">
            <i>
              <img
                src="../assets/images/common/ico_floating_navi03.png"
                alt=""
              />
            </i>
            <span>무료견적요청</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default FloatingBtn;
