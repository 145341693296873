import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const RADIAN = Math.PI / 180;
const data = [
  { name: "A", value: 24, color: "#FF0000" },
  { name: "B", value: 1, color: "#FFFFFF" },
  { name: "C", value: 24, color: "#6B5BB4" },
  { name: "D", value: 1, color: "#FFFFFF" },
  { name: "E", value: 24, color: "#42749E" },
  { name: "F", value: 1, color: "#FFFFFF" },
  { name: "G", value: 25, color: "#02AEED" },
];
const cx = 120;
const cy = 100;
const iR = 60;
const oR = 100;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
    />,
  ];
};

const pieChartWithNeedle = (props) => {
  const value = parseInt(props.data);

  return (
    <div className="pie">
      <PieChart width={250} height={130}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#BD0304"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, data, cx, cy, iR, oR, "#07080B")}
      </PieChart>
      <div className="pie-text-area">
        <div className="pie-title">{props.title}</div>
        <div className="pie-data">{value}%</div>
      </div>
    </div>
  );
};

export default pieChartWithNeedle;
