import React, { useEffect, useState } from "react";
import { userInfoState } from "recoil/userStore";
import { useRecoilValue } from "recoil";
import useAxios from "hooks/useAxios";
import moment from "moment";
import LineChartMonth from "../../../components/common/chart/lineChartMonth";
import PieChartHalf from "../../../components/common/chart/pieChartHalf";
import PieChartWithNeedle from "../../../components/common/chart/pieChartWithNeedle";
import LineChartWork from "../../../components/common/chart/lineChartWork";

//BD0304, 75455E, 42749E, 02AEED
const DashboardPage = (props) => {
  const userID = props.user_id;
  const { apiUrls, axiosFetch } = useAxios();
  const [contractInfo, setContractInfo] = useState();
  const [checklistInfo, setChecklistInfo] = useState([]);
  const [timeBar, setTimeBar] = useState([]);
  const [totalHour, setTotalHour] = useState();
  const [totalHourBar, setTotalHourBar] = useState("0%");
  const [visitInfo, setVisitInfo] = useState({
    count: 0,
    total: 0,
  });
  const [checklistPerInfo, setChecklistPerInfo] = useState();
  const [physicalPerInfo, setPhysicalPerInfo] = useState();
  const [servicePerInfo, setServicePerInfo] = useState();
  const [physicalInfo, setPhysicalInfo] = useState();
  const [serviceInfo, setServiceInfo] = useState();
  const [workTimeInfo, setWorkTimeInfo] = useState([]);
  const [days, setDays] = useState([]);

  const getContractCheckListInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getContractCheckListInfo,
      requestConfig: {
        user_id: userID,
      },
    })
      .then((res) => {
        if (res.success) {
          setContractInfo(res.contractInfo);
          setChecklistInfo(res.checklistInfo);
          setDays(res.contractInfo.contract_days.split(","));
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getContractCheckListInfo : ", err);
      });
  };

  const countVisitTime = () => {
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    let totalMinute = checklist.reduce(function add(sum, currValue) {
      return (
        sum +
        moment(currValue.check_end_date).diff(
          moment(currValue.check_start_date),
          "minutes"
        )
      );
    }, 0);

    let workDays = countDays(
      moment().startOf("month").format("YYYY-MM-DD"),
      moment().endOf("month").format("YYYY-MM-DD")
    );

    let monthHour = Math.round(
      (workDays * contractInfo?.contract_total_time) / 60
    );
    monthHour = ((monthHour % 6) + 1) * 6;

    if (!isNaN(monthHour)) {
      let numbers = [1, 2, 3, 4, 5, 6];
      setTimeBar(() =>
        numbers.map((data) => ({ data: (monthHour / 6) * data }))
      );

      let hour = Math.round(totalMinute / 60);
      setTotalHour(hour);
      setTotalHourBar(Math.round((hour / monthHour) * 100) + "%");
    }
  };

  const countDays = (start, end) => {
    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let count = 0;
    let temp_date = startDate;
    let dates = [];

    if (days !== null) {
      while (true) {
        if (temp_date > endDate) {
          break;
        } else {
          let tmp = moment(temp_date).day() - 1;
          if (tmp === -1) tmp = 6;
          if (days[tmp] === "1") {
            count++;
            dates.push(new Date(temp_date));
          }
          temp_date = moment(temp_date).add(1, "day").format("YYYY-MM-DD");
        }
      }
    }

    return count;
  };

  const countVisite = () => {
    setVisitInfo({
      count: totalVisite(
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD")
      ),
      total: countDays(
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD")
      ),
    });
  };

  const countCheckList = () => {
    setChecklistPerInfo(
      Math.round(
        (totalVisite(
          moment().startOf("month").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD")
        ) *
          100) /
          countDays(
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
            false
          )
      ) > 100
        ? 100
        : Math.round(
            (totalVisite(
              moment().startOf("month").format("YYYY-MM-DD"),
              moment().format("YYYY-MM-DD")
            ) *
              100) /
              countDays(
                moment().startOf("month").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                false
              )
          )
    );
  };

  const totalVisite = (start, end) => {
    let startDate = start;
    let endDate = end;

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    return checklist?.length;
  };

  const countPhysical = () => {
    setPhysicalPerInfo(totalPhysical());
  };

  const totalPhysical = () => {
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return 0;

    let totalCount = 0;
    let checkCount = 0;
    checklist.map((el) => {
      totalCount += el.check_physical_name.split(",").length;
      checkCount += el.check_physicals.split("0").length - 1;
    });

    if (checkCount === 0) {
      return 100;
    } else {
      return Math.round(((totalCount - checkCount) * 100) / totalCount);
    }
  };

  const countService = () => {
    setServicePerInfo(totalService());
  };

  const totalService = () => {
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return 0;

    let totalCount = 0;
    let checkCount = 0;
    checklist.map((el) => {
      totalCount += el.check_service_name.split(",").length;
      checkCount += el.check_services.split("0").length - 1;
    });

    if (checkCount === 0) {
      return 100;
    } else {
      return Math.round(((totalCount - checkCount) * 100) / totalCount);
    }
  };

  const countWorkTime = () => {
    setWorkTimeInfo(totalWorkTime());
  };

  const totalWorkTime = () => {
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return null;

    let data = [];
    checklist.map((el) => {
      data.push({
        name: moment(el.check_start_date).format("MM.DD"),
        시작: parseFloat(moment(el.check_start_date).format("HH.mm")),
        종료: parseFloat(moment(el.check_end_date).format("HH.mm")),
      });
    });

    return data;
  };

  //물리적범위, 서비스항목 이행율
  const monthPhysicalService = () => {
    let startDate = moment().startOf("month").format("YYYY-MM-DD");
    let endDate = moment().endOf("month").format("YYYY-MM-DD");

    let checklist = checklistInfo?.filter(
      (x) =>
        moment(x.check_start_date).isSameOrAfter(startDate) &&
        moment(x.check_start_date).isSameOrBefore(endDate)
    );

    if (checklist.length === 0) return setPhysicalInfo(null);

    setPhysicalInfo(
      checklist.map((el) => ({
        data:
          100 -
          Math.round(
            ((el.check_physicals.split("0").length - 1) /
              el.check_physical_name.split(",").length) *
              100
          ),
        name: moment(el.check_start_date).format("MM.DD"),
      }))
    );

    setServiceInfo(
      checklist.map((el) => ({
        data:
          100 -
          Math.round(
            ((el.check_services.split("0").length - 1) /
              el.check_service_name.split(",").length) *
              100
          ),
        name: moment(el.check_start_date).format("MM.DD"),
      }))
    );
  };

  useEffect(() => {
    if (userID !== undefined) {
      getContractCheckListInfo();
    }
  }, []);

  useEffect(() => {
    getContractCheckListInfo();
  }, [userID]);

  useEffect(() => {
    countVisitTime();
    countCheckList();
    countVisite();
    countPhysical();
    countService();
    countWorkTime();
    monthPhysicalService();
  }, [checklistInfo]);

  return (
    <div className="content">
      <div className="title">
        <div>
          <h1>ZIM(Zone Interactive Manager)</h1>
          <p>
            클리니션의 ZIM은 고객서비스관리 강화, 업무생산성 향상, 운영안정성,
            추가 제안 제시를 달성합니다.
          </p>
        </div>
      </div>
      <div className="dashboard-area webpart-area">
        <div className="webpart-item webpart-visit-time">
          <div className="webpart-monthly-time">
            <h2>이달의 방문시간 (총 {totalHour}시간)</h2>
            <div className="chart-bar">
              {/* left에 값을 %로 환산  */}
              <span className="current" style={{ left: totalHourBar }}>
                {/* {totalHour}H */}
              </span>
              <ul className="list">
                {timeBar?.map((item) => (
                  <li key={item.data}>
                    <span>{item.data}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="webpart-item webpart-monthly-statistics x2 y2">
          <div className="webpart-monthly-sevice">
            <h2>이달의 월간서비스 진행</h2>
            <div className="pie-area">
              <PieChartHalf
                count={visitInfo.count}
                totalCount={visitInfo.total}
              />
              <PieChartWithNeedle title="체크리스트" data={checklistPerInfo} />
              <PieChartWithNeedle title="물리적 범위" data={physicalPerInfo} />
              <PieChartWithNeedle
                title="서비스항목이행"
                data={servicePerInfo}
              />
            </div>
          </div>
        </div>

        <div className="webpart-item webpart-monthly-complete x2 y2">
          <h2>정기클리닝 소요 시간</h2>
          <LineChartWork data={workTimeInfo} />
        </div>

        <div className="webpart-item webpart-service-range-rate x2 xy">
          <h2>물리적범위 서비스 이행율</h2>
          <LineChartMonth data={physicalInfo} lineColor="#29ABE2" word="%" />
        </div>

        <div className="webpart-item webpart-service-list-rate x2 xy">
          <h2>서비스항목이행율</h2>
          <LineChartMonth data={serviceInfo} lineColor="#623189" word="%" />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
