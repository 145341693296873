import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxios from "hooks/useAxios";
import Select from "react-select";
import DatePickerTimeComponent from "../../../components/common/datePickerTimeComponent";
import { ConstructionOutlined, CribSharp } from "@mui/icons-material";
import axios from "axios";

const WriteCheckList = () => {
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch, axiosFetchForm } = useAxios();
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState();
  const [physicals, setPhysicals] = useState();
  const [services, setServices] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [addr1, setAddr1] = useState("");
  const [addr2, setAddr2] = useState("");
  const [comment, setComment] = useState("");
  const [renderImages, setRenderImages] = useState([]);
  const [files, setFiles] = useState([]);

  const selectedCustomerHandler = (value) => {
    setSelectedCustomerIndex(value);
    let physicalArray = customerList[value]?.contract_physical_name?.split(",");
    setPhysicals(() =>
      physicalArray.map((physical) => ({
        name: physical,
        checked: true,
      }))
    );
    let serviceArray = customerList[value]?.contract_service_name?.split(",");
    setServices(() =>
      serviceArray.map((service) => ({
        name: service,
        checked: true,
      }))
    );
    setAddr1(customerList[value]?.company_addr1);
    setAddr2(customerList[value]?.company_addr2);
  };

  const physicalCheckboxHandler = (data, index) => {
    setPhysicals((physicals) =>
      physicals.map((physical, currentIndex) =>
        currentIndex === index ? { ...physical, checked: data } : physical
      )
    );
  };

  const serviceCheckboxHandler = (data, index) => {
    setServices((services) =>
      services.map((service, currentIndex) =>
        currentIndex === index ? { ...service, checked: data } : service
      )
    );
  };

  const fileAddHandler = (e) => {
    const imageLists = e.target.files;
    let imageUrlLists = [...renderImages];

    for (let i = 0; i < imageLists.length; i++) {
      const currentImageUrl = URL.createObjectURL(imageLists[i]);
      imageUrlLists.push(currentImageUrl);
    }

    if (imageUrlLists.length > 3) {
      imageUrlLists = imageUrlLists.slice(0, 3);
    }

    setRenderImages(imageUrlLists);
    setFiles(
      Array.from(files || [])
        .concat(Array.from(imageLists || []))
        .slice(0, 3)
    );
  };

  const fileDeleteHandler = (e, index) => {
    e.preventDefault();
    setRenderImages(
      renderImages.filter((_, currentIndex) => currentIndex !== index)
    );
    setFiles(
      Array.from(files || []).filter(
        (_, currentIndex) => currentIndex !== index
      )
    );
  };

  const getContractInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getServiceListU,
      requestConfig: {
        user_id: userInfo.user_id,
      },
    })
      .then((res) => {
        if (res.success) {
          res.contractInfo.map((data, index) => {
            data.value = index;
            data.label = data.company_name;
          });
          setCustomerList(res.contractInfo);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getServiceListU : ", err);
      });
  };

  const saveCheckList = () => {
    const customerInfo = customerList[selectedCustomerIndex];
    let checkListInfo = {};

    checkListInfo.user_id = customerInfo.user_id;
    checkListInfo.user_name = customerInfo.user_name;
    checkListInfo.user_tel = customerInfo.user_tel;
    checkListInfo.company_name = customerInfo.company_name;
    checkListInfo.company_number = customerInfo.company_number;
    checkListInfo.company_addr1 = customerInfo.company_addr1;
    checkListInfo.company_addr2 = customerInfo.company_addr2;
    checkListInfo.check_start_date = selectedStartDate;
    checkListInfo.check_end_date = selectedEndDate;
    checkListInfo.check_physical_name = customerInfo.contract_physical_name;
    checkListInfo.check_service_name = customerInfo.contract_service_name;
    checkListInfo.check_comment = comment;
    checkListInfo.partner_name = customerInfo.partner_name;
    checkListInfo.partner_tel = customerInfo.partner_tel;
    checkListInfo.partner_company_name = customerInfo.partner_company_name;

    let physicalStr = "";
    physicals.map((physical) => {
      if (physical.checked) {
        physicalStr += "1,";
      } else physicalStr += "0,";
    });
    let serviceStr = "";
    services.map((service) => {
      if (service.checked) {
        serviceStr += "1,";
      } else serviceStr += "0,";
    });
    checkListInfo.check_physicals = physicalStr.substring(
      0,
      physicalStr.length - 1
    );
    checkListInfo.check_services = serviceStr.substring(
      0,
      serviceStr.length - 1
    );

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("img", files[i]);
    }

    formData.append("checkListInfo", JSON.stringify(checkListInfo));

    axiosFetchForm({
      url: apiUrls.addCheckList,
      method: "POST",
      formData: formData,
    })
      .then((res) => {
        alert(res.message);
      })
      .catch((err) => {
        console.log("[ERROR] addCheckList : ", err);
      });

    return;
  };

  const checkedPhysicalsHandler = (e) => {
    e.preventDefault();
    setPhysicals((physicals) =>
      physicals.map((physical) => ({ ...physical, checked: true }))
    );
  };

  const checkedServiceHandler = (e) => {
    e.preventDefault();
    setServices((services) =>
      services.map((services) => ({ ...services, checked: true }))
    );
  };

  useEffect(() => {
    getContractInfo();
  }, []);

  return (
    <div className="content">
      <div className="title">
        <div>
          <h1>ZIM(Zone Interactive Manager)</h1>
          <p>
            클리니션의 ZIM은 고객서비스관리 강화, 업무생산성 향상, 운영안정성,
            추가 제안 제시를 달성합니다.
          </p>
        </div>
      </div>
      <div className="admin-dialog" style={{ background: "#F7F7F7" }}>
        <div
          className="admin-dialog-box contract"
          style={{
            maxWidth: "100%",
            maxHeight: "250vh",
            background: "#F7F7F7",
          }}
        >
          <div className="admin-dialog-area" style={{ padding: "0" }}>
            <div className="dialog-content">
              <div className="admin-dialog-item">
                <h3 className="adi-title">고객사</h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={customerList}
                    value={customerList.filter(function (option) {
                      return option.value === selectedCustomerIndex;
                    })}
                    onChange={(e) => selectedCustomerHandler(e.value)}
                  ></Select>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">주소지</h3>
                <div className="adi-content">
                  {addr1} {addr2}
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">방문시작</h3>
                <div className="adi-content">
                  <DatePickerTimeComponent
                    selected={selectedStartDate}
                    onChange={setSelectedStartDate}
                    withPortal={true}
                  />
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">방문완료</h3>
                <div className="adi-content time-start-end">
                  <DatePickerTimeComponent
                    selected={selectedEndDate}
                    onChange={setSelectedEndDate}
                    withPortal={true}
                  />
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3
                  className="adi-title"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => checkedPhysicalsHandler(e)}
                >
                  물리적 범위
                </h3>
                <div className="adi-content">
                  <ul className="adi-item-row">
                    {physicals?.map((item, index) => (
                      <li>
                        <div className="input-check-zim">
                          <input
                            key={item.name}
                            type="checkbox"
                            id={`inpp0${index}`}
                            checked={item.checked}
                            onChange={(e) => {
                              physicalCheckboxHandler(e.target.checked, index);
                            }}
                          />
                          <label for={`inpp0${index}`}>{item.name}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3
                  className="adi-title"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => checkedServiceHandler(e)}
                >
                  서비스 항목
                </h3>
                <div className="adi-content">
                  <ul className="adi-item-row">
                    {services?.map((item, index) => (
                      <li>
                        <div className="input-check-zim">
                          <input
                            key={item.name}
                            type="checkbox"
                            id={`inps0${index}`}
                            checked={item.checked}
                            onChange={(e) => {
                              serviceCheckboxHandler(e.target.checked, index);
                            }}
                          />
                          <label for={`inps0${index}`}>{item.name}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">완료사진</h3>
                <div className="adi-content">
                  <label
                    htmlFor="input-file"
                    onChange={(e) => fileAddHandler(e)}
                  >
                    <input
                      id="input-file"
                      type="file"
                      multiple
                      accept="image/jpg, image/jpeg, image/png"
                      style={{ display: "none" }}
                    />
                    <span style={{ cursor: "pointer" }}>
                      이미지 3장 선택하기
                    </span>
                  </label>
                </div>
                <div className="adi-content">
                  <ul className="adi-pictures">
                    {renderImages?.map((item, index) => (
                      <>
                        <li>
                          <img
                            src={item}
                            alt=""
                            onClick={(e) => {
                              fileDeleteHandler(e, index);
                            }}
                          />
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-title">
                  기타사항
                  <br />
                  (구두서술 필요부분)
                </h3>
                <div className="adi-content">
                  <textarea
                    className="textarea"
                    cols="30"
                    rows="10"
                    defaultValue={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="btn-area">
                <button
                  type="button"
                  style={{
                    background: "#623189",
                    color: "#ffffff",
                    cursor: "pointer",
                  }}
                  onClick={saveCheckList}
                >
                  작성
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteCheckList;
