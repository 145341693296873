import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "hooks/useAxios";
import Select from "react-select";

const FeedbackPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { apiUrls, axiosFetch } = useAxios();
  const [service, setService] = useState();
  const [cleaning, setCleaning] = useState();
  const [kind, setKind] = useState();
  const [zim, setZim] = useState();
  const [work, setWork] = useState();
  const [comment1, setComment1] = useState("");
  const [comment2, setComment2] = useState("");
  const [comment3, setComment3] = useState("");
  const [userInfo, setUserInfo] = useState();

  const selectList = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];

  const selectWork = [
    { value: 1, label: "상당히 감소하였다" },
    { value: 2, label: "감소하였다" },
    { value: 3, label: "기존과 같다" },
    { value: 4, label: "늘어났다" },
  ];

  const confirmID = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.confirmID,
      requestConfig: {
        user_id: userId,
      },
    })
      .then((res) => {
        if (!res.success) {
          alert(res.message);
          navigate("/");
        } else {
          setUserInfo(res.userInfo);
        }
      })
      .catch((err) => {
        console.log("[ERROR] confirmID : ", err);
      });
  };

  const saveFeedback = () => {
    if (
      service === undefined ||
      cleaning === undefined ||
      kind === undefined ||
      zim === undefined
    ) {
      alert("평가항목을 선택해주세요.");
      return;
    }

    let feedbackInfo = {
      user_id: userInfo.user_id,
      company_name: userInfo.company_name,
      company_number: userInfo.company_number,
      user_name: userInfo.user_name,
      user_tel: userInfo.user_tel,
      user_email: userInfo.user_email,
      service: service,
      cleaning: cleaning,
      kind: kind,
      zim: zim,
      work: work,
      comment1: comment1,
      comment2: comment2,
      comment3: comment3,
    };

    axiosFetch({
      method: "post",
      url: apiUrls.addFeedbackInfo,
      requestConfig: {
        feedbackInfo: feedbackInfo,
      },
    })
      .then((res) => {
        alert(res.message);
        if (res.success) {
          navigate("/");
        }
      })
      .catch((err) => {
        console.log("[ERROR] addFeedbackInfo : ", err);
      });
  };

  useEffect(() => {
    confirmID();
  }, []);

  return (
    <div className="container">
      <section className="visual-top inquiry">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>클리니션의 활동을 평가해주세요.</p>
            <h2>고객 만족도 조사</h2>
          </div>
        </div>
      </section>
      <section className="cleaning-section-special">
        <div className="inner">
          <div
            className="text-area"
            style={{
              paddingBottom: "0px",
            }}
          >
            <h2>만족도 조사</h2>
            <div className="ment">
              클리니션은 고객의 니즈에 충족하는 모든 서비스를 제공합니다.
            </div>
          </div>
        </div>
      </section>

      <div
        className="admin-dialog"
        style={{
          background: "#FFFFFF",
          maxWidth: "880px",
          margin: "0 auto",
          padding: "10px",
        }}
      >
        <div
          className="admin-dialog-box"
          style={{
            maxHeight: "250vh",
            maxWidth: "880px",
            background: "#FFFFFF",
          }}
        >
          <div className="admin-dialog-area" style={{ padding: "0" }}>
            <div className="dialog-content">
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">
                  전반적인 클리니션 서비스에 대한 만족도는?
                </h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={selectList}
                    onChange={(e) => setService(e.value)}
                  ></Select>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">
                  청소 상태에 대한 만족도는?
                </h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={selectList}
                    onChange={(e) => setCleaning(e.value)}
                  ></Select>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">직원의 친절함 만족도는?</h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={selectList}
                    onChange={(e) => setKind(e.value)}
                  ></Select>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">
                  운영관리시스템(ZIM)에 대한 만족도는?
                </h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={selectList}
                    onChange={(e) => setZim(e.value)}
                  ></Select>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">
                  클리니션 서비스 진행 이후 청소 관리 업무는?
                </h3>
                <div className="adi-content">
                  <Select
                    className="admin-select adi-company-category"
                    options={selectWork}
                    onChange={(e) => setWork(e.value)}
                  ></Select>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">
                  최초 계약한 서비스 내용이 잘 진행되고 있다고 생각하나?
                </h3>
                <div className="adi-content">
                  <textarea
                    className="textarea"
                    defaultValue={comment1}
                    onChange={(e) => setComment1(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">
                  클리니션 서비스 받은 이후에 가장 만족스러운 점은?
                </h3>
                <div className="adi-content">
                  <textarea
                    className="textarea"
                    defaultValue={comment2}
                    onChange={(e) => setComment2(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="admin-dialog-item">
                <h3 className="adi-feedback-title">
                  기타 의견을 자유롭게 적어주세요.
                </h3>
                <div className="adi-content">
                  <textarea
                    className="textarea"
                    defaultValue={comment3}
                    onChange={(e) => setComment3(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="btn-area">
                <button
                  type="button"
                  style={{ cursor: "pointer" }}
                  onClick={saveFeedback}
                >
                  제출
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackPage;
