import React, { useEffect, useState } from "react";
import useAxiosAuthFunction from "hooks/useAxiosAuth";

const CustomerList = (props) => {
  const userInfo = props.userInfo;
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [partnerList, setPartnerList] = useState([]);

  const getContractInfo = () => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getServiceList,
      requestConfig: {
        company_name: userInfo.company_name,
      },
    })
      .then((res) => {
        if (res.success) {
          setPartnerList(res.partnerList);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getServiceList : ", err);
      });
  };

  useEffect(() => {
    getContractInfo();
  }, []);

  return (
    <div className="admin-dialog dialog-wrap" id="modal_admin_detail">
      <div className="dialog-box admin-dialog-box contract">
        <div className="admin-dialog-area">
          <div className="title-area">
            <h2>서비스 협력사</h2>
          </div>
          {partnerList?.map((item) => (
            <div className="dialog-content">
              <div className="admin-dialog-item">
                <h4 className="adi-title">사업자명</h4>
                <div className="adi-content">{item.company_name}</div>
              </div>
              <div className="admin-dialog-item">
                <h4 className="adi-title">사업자번호</h4>
                <div className="adi-content">{item.company_number}</div>
              </div>
              <div className="admin-dialog-item">
                <h4 className="adi-title">협력사 계약금액(월)</h4>
                <div className="adi-content">{item.partner_amount}</div>
              </div>
              <div className="admin-dialog-item">
                <h4 className="adi-title">현장팀</h4>
                <div className="adi-content">{item.partner_name}</div>
              </div>
              <div className="admin-dialog-item">
                <h4 className="adi-title">연락처</h4>
                <div className="adi-content">{item.partner_tel}</div>
              </div>
            </div>
          ))}

          <div className="btn-area">
            <button type="button" onClick={props.closeModal}>
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
