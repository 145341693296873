import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const cx = 80;
const cy = 80;
const iR = 40;
const oR = 80;

const PieChartService = (props) => {
  const value = parseInt(props.count);
  const totalCount = parseInt(props.totalCount);
  const realValue = (value / totalCount) * 100;

  const data = [
    { name: "A", value: realValue, color: "#B7EAD4" },
    { name: "C", value: 100 - realValue, color: "transparent" },
  ];

  return (
    <div className="pie">
      <PieChart width={170} height={170}>
        <Pie
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          stroke="transparent"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

export default PieChartService;
