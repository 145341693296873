import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import StepOne from "./signUpPage/stepOne";
import StepTwo from "./signUpPage/stepTwo";
import StepThree from "./signUpPage/stepThree";

const SignUp = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const { watch } = useForm({ mode: "onBlur" });

  const joinpwd = useRef();
  joinpwd.current = watch("join_pw");

  return (
    <>
      <div className="container login">
        <div className="member-section">
          {stepIndex === 0 ? (
            <StepOne setStepIndex={setStepIndex} />
          ) : stepIndex === 1 ? (
            <StepTwo setStepIndex={setStepIndex} />
          ) : (
            <StepThree setStepIndex={setStepIndex} />
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
