import React from "react";
import DatePicker from "react-datepicker";
import "assets/css/datePicker.css";
import { ko } from "date-fns/esm/locale";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";

const DatePickerTimeComponent = (props) => {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <DatePicker
      className="input-admin"
      withPortal={props.withPortal}
      showTimeSelect
      selected={props.selected}
      onChange={props.onChange}
      dateFormat={"yyyy-MM-dd aa h:mm "}
      locale={ko}
      renderCustomHeader={({
        date,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        decreaseMonth,
        increaseMonth,
      }) => (
        <div
          style={{
            margin: "10px 15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="btn_month btn_month-prev"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <img src="/assets/images/common/monthPrev.png" alt="" />
          </div>
          <div
            className="month-day"
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#4F4F4F",
            }}
          >
            {getYear(date)}년 {months[getMonth(date)]}월
          </div>

          <div
            className="btn_month btn_month-next"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <img src="/assets/images/common/monthNext.png" alt="" />
          </div>
        </div>
      )}
    />
  );
};

export default DatePickerTimeComponent;
