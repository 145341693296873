import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { businessPageIndexState } from "recoil/boardStore";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";

function BusinessList() {
  const userInfo = useRecoilValue(userInfoState);
  const [businessPageIndex, setBusinessPageIndex] = useRecoilState(
    businessPageIndexState
  );
  const [businessResult, setBusinessResult] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const { apiUrls, axiosFetch } = useAxiosFunction();

  const getBusinessInfo = (reqData) => {
    window.scrollTo(0, 850);

    setBusinessPageIndex(reqData);

    axiosFetch({
      method: "post",
      url: apiUrls.getBusinessList,
      requestConfig: { currentNavPage: reqData },
    })
      .then((res) => {
        if (res.success) {
          setBusinessResult(res.result);
          setBusinessList(res.businessList);
          setPageList(res.pageList);
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  useEffect(() => {
    getBusinessInfo(businessPageIndex);
  }, []);

  return (
    <>
      <section className="inner board">
        {userInfo.user_type === "A" ? (
          <div className="write-btn">
            <Link to={`/business/write`}>
              <button className="button-primary size-s">작성하기</button>
            </Link>
          </div>
        ) : null}

        <div className="board-gallery-list">
          <ul>
            {businessList?.map((item, index) => (
              <li key={index}>
                <Link to={`/business/detail/${item.board_id}`}>
                  <div className="img">
                    {item.thumbnail ? (
                      <img src={`${item.thumbnail}`} alt="" />
                    ) : (
                      <img src="/assets/images/main/system01.png" alt="" />
                    )}{" "}
                  </div>
                  <div className="text">
                    <h3 className="title">{item.title}</h3>
                    <div className="info">
                      {/* <div className="date">
                        {moment(item.board_date).format("YYYY.MM.DD")}
                      </div> */}
                      <div className="view">view {item.read_count}</div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="board-paginate">
          <Link
            className="prev"
            onClick={() =>
              getBusinessInfo(
                businessResult.currentPage - 10 < 1
                  ? 1
                  : businessResult.currentPage - 10
              )
            }
          >
            이전
          </Link>
          <ul>
            {pageList?.map((item, index) => (
              <li
                key={index}
                className={
                  item === businessResult.currentPage ? "current" : null
                }
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => getBusinessInfo(item)}
                >
                  {item}
                </div>
              </li>
            ))}
          </ul>
          <Link
            className="next"
            onClick={() =>
              getBusinessInfo(
                pageList[pageList.length - 1] + 1 < businessResult.maxPage
                  ? pageList[pageList.length - 1] + 1
                  : pageList[pageList.length - 1]
              )
            }
          ></Link>
        </div>
      </section>
    </>
  );
}

export default BusinessList;
