import React from "react";
import "assets/css/style.css";
import "assets/css/admin.css";

const CustomerInfo = (props) => {
  const userInfo = props.userInfo;

  return (
    <div className="admin-dialog dialog-wrap" id="modal_admin_detail">
      <div className="dialog-box admin-dialog-box customer">
        <div className="admin-dialog-area">
          <div className="title-area">
            <h2>고객 상세 정보</h2>
          </div>
          <div className="dialog-content">
            <div className="admin-dialog-item">
              <h3 className="adi-title">ID</h3>
              <div className="adi-content">{userInfo.user_id}</div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">회사명</h3>
              <div className="adi-content">{userInfo.company_name}</div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">사업자번호</h3>
              <div className="adi-content">{userInfo.company_number}</div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">주소지</h3>
              <div className="adi-content">
                {userInfo.company_addr1} {userInfo.company_addr2}
              </div>
            </div>
            <div className="admin-dialog-item">
              <h3 className="adi-title">대표번호</h3>
              <div className="adi-content">{userInfo.company_tel}</div>
            </div>
            <div className="admin-dialog-item complex">
              <h3 className="adi-title">담당자</h3>
              <div className="adi-content">
                <div className="admin-dialog-item">
                  <h4 className="adi-title">이메일</h4>
                  <div className="adi-content">{userInfo.user_email}</div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">이름</h4>
                  <div className="adi-content">{userInfo.user_name}</div>
                </div>
                <div className="admin-dialog-item">
                  <h4 className="adi-title">연락처</h4>
                  <div className="adi-content">{userInfo.user_tel}</div>
                </div>
              </div>
            </div>
            <div className="btn-area">
              <button type="button" onClick={props.closeModal}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
