import React from "react";
import { RequiredAuth } from "components/common/requiredAuth";
import { RequiredAdminAuth } from "components/common/requiredAdminAuth";

//메인화면 및 메뉴
import MainPage from "pages/main/mainPage";
import CoreValuePage from "pages/main/coreValuePage";
import RegularClinicPage from "pages/main/regularClinicPage";
import IrregularClinicPage from "pages/main/irregularClinicPage";
import SpecialClinicPage from "pages/main/specialClinicPage";
import RequestConsultingPage from "pages/main/requestConsultingPage";
import BusinessPage from "pages/main/businessPage";
import BusinessDetail from "pages/main/business/businessDetail";
import BusinessEdit from "pages/main/business/businessEdit";
import BusinessWrite from "pages/main/business/businessWrite";
import FeedbackPage from "pages/main/feedbackPage";

//로그인
import Login from "pages/login/login";
import SignUp from "pages/login/signUpPage";
import ReqId from "pages/login/reqIdPage";
import ReqPw from "pages/login/reqPwPage";

//어드민
import AdminPage from "pages/admin/adminPage";

//ZIM
import ZimPage from "pages/zim/zimPage";

import CustomerAuthPage from "pages/login/signUpPage/customerAuthPage";

const router = [
  /* 메인페이지 */
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/corevalue",
    element: <CoreValuePage />,
  },
  {
    path: "/regularclinic",
    element: <RegularClinicPage />,
  },
  {
    path: "/irregularclinic",
    element: <IrregularClinicPage />,
  },
  {
    path: "/specialclinic",
    element: <SpecialClinicPage />,
  },
  {
    path: "/reqconsulting",
    element: <RequestConsultingPage />,
  },
  {
    path: "/business",
    element: <BusinessPage />,
    children: [
      {
        path: `write`,
        element: <BusinessWrite />,
      },
      {
        path: `edit/:postId`,
        element: <BusinessEdit />,
      },
      {
        path: `detail/:postId`,
        element: <BusinessDetail />,
      },
    ],
  },

  /* 유저 - 로그인 등 */
  { path: "/login", element: <Login /> },
  { path: "/signUp", element: <SignUp /> },
  { path: "/reqId", element: <ReqId /> },
  { path: "/reqPw", element: <ReqPw /> },

  { path: "/admin", element: <AdminPage /> },

  /* ZIM */
  { path: "/zim", element: <ZimPage /> },

  /* Feedback */
  { path: "/feedback/:userId", element: <FeedbackPage /> },
  // { path: "/checkplus_success", element: <Login /> },
  // { path: "/checkplus_fail", element: <Login /> },
];

export default router;
