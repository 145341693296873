import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { CustomRegExp } from "helpers/customRegExp";

const ReqPw = () => {
  const [idInfo, setIdInfo] = useState("");
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const newPw = useRef();
  newPw.current = watch("new_pw");

  const reqPw = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.reqPw,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setIdInfo(res.user_id);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  const updatePw = (reqData) => {
    reqData.req_id = idInfo;

    axiosFetch({
      method: "post",
      url: apiUrls.updatePw,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          alert(res.message);
          navigate("/");
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (userInfo.userInfo != null) {
      navigate("/");
    }
  }, []);

  return (
    <div className="container login">
      <div className="member-section">
        {idInfo === "" ? (
          <div className="member-area">
            <div className="title-area">
              <h2>비밀번호 찾기</h2>
              <p>가입한 이름과 전화번호를 입력하세요.</p>
            </div>
            <form onSubmit={handleSubmit(reqPw)}>
              <div className="input-area">
                <div className="input-style">
                  <label for="req_id">아이디</label>
                  <InputUnit
                    label="req_id"
                    type="text"
                    holder="아이디"
                    register={register("req_id", {
                      required: "아이디는 필수 입력 값 입니다.",
                      minLength: {
                        value: 3,
                        message: "최소 3자 이상 입력 해 주세요",
                      },
                      maxLength: {
                        value: 22,
                        message: "최대 22자 이하로 입력 해 주세요",
                      },
                      pattern: {
                        value: /^[a-z0-9]+[a-z0-9]+$/i,
                        message: "한글 및 특수문자는 아이디에 사용할 수 없어요",
                      },
                    })}
                    errors={formErrors}
                  />
                </div>
                <div className="input-style">
                  <label for="req_tel">전화번호</label>
                  <InputUnit
                    type="text"
                    id="req_tel"
                    holder="전화번호"
                    register={register("req_tel", {
                      required: "전화번호는 필수 입력 값 입니다.",
                      pattern: CustomRegExp("tel"),
                    })}
                    errors={formErrors}
                  />
                </div>
              </div>
              <div className="button-area">
                <button
                  type="submit"
                  className="button-primary size-l"
                  disabled={isSubmitting}
                >
                  <span>본인인증하기</span>
                </button>
              </div>
            </form>
            <div className="extra-area">
              <ul>
                <li>
                  <Link to="/login">로그인</Link>
                </li>
                <li>
                  <Link to="/reqId">ID 찾기</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/signup">회원가입</Link>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="member-area">
            <div className="title-area">
              <h2>비밀번호 설정</h2>
              <p>새로운 비밀번호를 입력하세요.</p>
            </div>
            <form onSubmit={handleSubmit(updatePw)}>
              <div className="input-area">
                <div className="input-style type2">
                  <label for="new_pw">비밀번호</label>
                  <InputUnit
                    type="password"
                    id="new_pw"
                    holder="비밀번호"
                    register={register("new_pw", {
                      required: "패스워드는 필수 입력 값 입니다.",
                      minLength: {
                        value: 8,
                        message: "최소 8자 이상 입력 해 주세요",
                      },
                      maxLength: {
                        value: 16,
                        message: "최대 16자 이하로 입력 해 주세요",
                      },
                    })}
                    errors={formErrors}
                  />
                  <p className="tip">
                    (영문 대소문자 / 숫자 / 특수문자 중 2가지 이상 조합,
                    8자~16자)
                  </p>
                </div>
                <div className="input-style type2">
                  <label for="join_pw_check">비밀번호 확인</label>
                  <InputUnit
                    type="password"
                    id="join_pw_check"
                    holder="비밀번호 확인"
                    register={register("join_pw_check", {
                      required: "",
                      validate: (value) => value === newPw.current,
                    })}
                    errors={formErrors}
                  />
                </div>
              </div>
              <div className="button-area">
                <button
                  type="submit"
                  className="button-primary size-l"
                  disabled={isSubmitting}
                >
                  <span>비밀번호 변경하기</span>
                </button>
              </div>
            </form>
            <div className="extra-area">
              <ul>
                <li>
                  <Link to="/login">로그인</Link>
                </li>
                <li>
                  <Link to="/reqId">ID 찾기</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/signup">회원가입</Link>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReqPw;
