const CustomRegExp = (value) => {
  let reg = {};
  if (value === "name") {
    reg = {
      value: /^[가-힣a-zA-Z]{2,20}$/,
      message: "올바르지 않은 이름입니다.",
    };
  } else if (value === "tel") {
    reg = {
      value: /^(01[016789]{1}|070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/,
      message: "올바르지 않은 전화번호입니다. '-' 없이 입력해주세요.",
    };
  } else if (value === "email") {
    reg = {
      value: /.+@.+..+/,
      message: "올바르지 않은 이메일입니다.",
    };
  } else if (value === "password") {
    reg = {
      value: 8,
      message: "패스워드는 최소 4자리 이상으로 입력해야합니다.",
    };
  } else if (value === "id") {
    reg = {
      value: /^[a-z0-9]+[a-z0-9]+$/i,
      message: "한글 및 특수문자는 아이디에 사용할 수 없습니다.",
    };
  } else if (value === "company") {
    reg = {
      value: /^[0-9]{3}[0-9]{2}[0-9]{5}$/,
      message: "올바르지 않은 사업자 등록번호입니다. '-' 없이 입력해 주세요.",
    };
  } else if (value === "birth") {
    reg = {
      value:
        /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
      message: "올바르지 않은 생년월일 입니다. 숫자만 8자리 입력해주세요.",
    };
  }
  return reg;
};

export { CustomRegExp };
