import React, { useState } from "react";
import UserListPage from "pages/admin/admin/userListPage";
import CustomerListPage from "pages/admin/admin/customerListPage";
import SubcontractListPage from "pages/admin/admin/subcontractListPage";
import ContractListPage from "pages/admin/admin/contractListPage";
import CheckListPage from "pages/admin/admin/checkListPage";
import FeedbackListPage from "pages/admin/admin/feedbackListPage";
import "assets/css/admin.css";

const AdminPage = () => {
  const [menuIndex, setMenuIndex] = useState(0);

  const ChangeMenu = (e, select) => {
    e.preventDefault();
    setMenuIndex(select);
  };
  return (
    <>
      <div className="container-admin">
        <div className="board-lnb">
          <h2 className="lnb-title">
            {menuIndex === 0
              ? "가입고객"
              : menuIndex === 1
              ? "고객사"
              : menuIndex === 2
              ? "협력사"
              : menuIndex === 3
              ? "계약현황"
              : menuIndex === 4
              ? "체크리스트"
              : menuIndex === 5
              ? "고객피드백"
              : null}
          </h2>
          <div className="board-category">
            <ul className="">
              <li className={menuIndex === 0 ? "active" : ""}>
                <span onClick={(e) => ChangeMenu(e, 0)}>가입고객</span>
              </li>
              <li className={menuIndex === 1 ? "active" : ""}>
                <span onClick={(e) => ChangeMenu(e, 1)}>고객사</span>
              </li>
              <li className={menuIndex === 2 ? "active" : ""}>
                <span onClick={(e) => ChangeMenu(e, 2)}>협력사</span>
              </li>
              <li className={menuIndex === 3 ? "active" : ""}>
                <span onClick={(e) => ChangeMenu(e, 3)}>계약현황</span>
              </li>
              <li className={menuIndex === 4 ? "active" : ""}>
                <span onClick={(e) => ChangeMenu(e, 4)}>체크리스트</span>
              </li>
              <li className={menuIndex === 5 ? "active" : ""}>
                <span onClick={(e) => ChangeMenu(e, 5)}>고객피드백</span>
              </li>
            </ul>
          </div>
        </div>

        {menuIndex === 0 ? (
          <UserListPage />
        ) : menuIndex === 1 ? (
          <CustomerListPage />
        ) : menuIndex === 2 ? (
          <SubcontractListPage />
        ) : menuIndex === 3 ? (
          <ContractListPage />
        ) : menuIndex === 4 ? (
          <CheckListPage />
        ) : menuIndex === 5 ? (
          <FeedbackListPage />
        ) : null}
      </div>
    </>
  );
};

export default AdminPage;
