import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAxiosFunction from "hooks/useAxios";
import { userInfoState } from "recoil/userStore";
import moment from "moment";

const BusinessDetail = () => {
  const userInfo = useRecoilValue(userInfoState);
  const [businessData, setBusinessData] = useState();
  const navigate = useNavigate();
  const { axiosFetch, apiUrls, axiosFetchDown } = useAxiosFunction();
  const { postId } = useParams();

  const onDetailArticle = (e) => {
    e.preventDefault();
    navigate("/business");
  };

  const onEditHandler = (e) => {
    e.preventDefault();
    navigate(`/business/edit/${postId}`);
  };

  const onDeleteHandler = (e) => {
    e.preventDefault();

    axiosFetch({
      method: "post",
      url: apiUrls.deleteBusiness,
      requestConfig: { postId: postId },
    })
      .then((res) => {
        if (res.success) {
          navigate("/business");
        }
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const businessDownload = (e) => {
    e.preventDefault();
    console.log(apiUrls.businessDownload + `/${businessData?.attached_file}`);
    axiosFetchDown({
      method: "get",
      url: apiUrls.businessDownload + `/${businessData?.attached_file}`,
      requestConfig: {},
    })
      .then((res) => {
        const href = URL.createObjectURL(res);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", businessData?.origin_file); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log("[ERROR] login : ", err);
      });
  };

  const businessDetail = () => {
    axiosFetch({
      method: "get",
      url: apiUrls.getBusinessDetail(postId),
    })
      .then((res) => {
        if (res.success) {
          setBusinessData(res.result);
        }
      })
      .catch((err) => {
        console.log("[ERROR] get Business detail : ", err);
      });
  };

  function createMarkup() {
    return { __html: businessData?.content };
  }

  useEffect(() => {
    businessDetail();
  }, []);

  return (
    <section className="inner business-gallery">
      <div className="detail-title-box">
        <div className="detail-writer">{businessData?.title}</div>
        <div className="detail-writer">
          <span>{moment(businessData?.board_date).format("YYYY/MM/DD")}</span>
          <span>조회수: {businessData?.read_count}</span>
        </div>
      </div>
      <div className="detail-content-box">
        <div
          className="board-content"
          dangerouslySetInnerHTML={createMarkup()}
        ></div>
        <div className="board-file">
          {businessData?.origin_file != null && (
            <Link onClick={businessDownload} href="#">
              <div className="board-comment">
                <div className="rt2" />
                <div className="title">
                  <span>첨부파일</span>
                </div>
                <div className="rt1" />
                <div className="file">
                  <img src="/assets/images/common/download.png" alt="" />
                  <span>{businessData?.origin_file}</span>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="btn-area">
        <button
          style={{ background: "#623189" }}
          type="button"
          className="button-primary size-s"
          onClick={onDetailArticle}
        >
          목록
        </button>
        <div className="divide" />
        {userInfo.user_type === "A" ? (
          <>
            <button
              style={{ background: "#623189" }}
              type="button"
              className="button-primary size-s"
              onClick={onEditHandler}
            >
              <span>수정</span>
            </button>
            <div className="divide" />
            <button
              style={{ background: "#623189" }}
              type="button"
              className="button-primary size-s"
              onClick={onDeleteHandler}
            >
              삭제
            </button>
          </>
        ) : null}
      </div>
    </section>
  );
};

export default BusinessDetail;
