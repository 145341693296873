import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxiosFunction from "hooks/useAxios";
import InputUnit from "components/InputUnit";
import { CustomRegExp } from "helpers/customRegExp";

const ReqId = () => {
  const [idInfo, setIdInfo] = useState("");
  const userInfo = useRecoilValue(userInfoState);

  const { apiUrls, axiosFetch } = useAxiosFunction();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    /* watch,     */
    formState: { isSubmitting, errors: formErrors },
  } = useForm({ mode: "onBlur" });

  const findId = (reqData) => {
    axiosFetch({
      method: "post",
      url: apiUrls.reqId,
      requestConfig: reqData,
    })
      .then((res) => {
        if (res.success) {
          setIdInfo(res.user_id);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (userInfo.userInfo != null) {
      navigate("/");
    }
  }, []);

  return (
    <div className="container login">
      <div className="member-section">
        <div className="member-area">
          {idInfo === "" ? (
            <>
              <div className="title-area">
                <h2>아이디 찾기</h2>
                <p>가입한 이름과 전화번호를 입력하세요.</p>
              </div>
              <form onSubmit={handleSubmit(findId)}>
                <div className="input-area">
                  <div className="input-style">
                    <label for="req_name">이름</label>
                    <InputUnit
                      type="text"
                      id="req_name"
                      holder="이름"
                      register={register("req_name", {
                        required: "이름은 필수 입력 값 입니다.",
                        pattern: CustomRegExp("name"),
                      })}
                      errors={formErrors}
                    />
                  </div>
                  <div className="input-style">
                    <label for="req_tel">전화번호</label>
                    <InputUnit
                      type="text"
                      id="req_tel"
                      holder="전화번호"
                      register={register("req_tel", {
                        required: "전화번호는 필수 입력 값 입니다.",
                        pattern: CustomRegExp("tel"),
                      })}
                      errors={formErrors}
                    />
                  </div>
                </div>
                <div className="button-area">
                  <button
                    type="submit"
                    className="button-primary size-l"
                    disabled={isSubmitting}
                  >
                    <span>아이디찾기</span>
                  </button>
                </div>
              </form>
            </>
          ) : (
            <div className="title-area">
              <h2>아이디 찾기</h2>
              <p>가입된 아이디는 {idInfo}입니다.</p>
            </div>
          )}

          <div className="extra-area">
            <ul>
              <li>
                <Link to="/login">로그인</Link>
              </li>
              <li>
                <Link to="/reqPw">비밀번호찾기</Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="/signup">회원가입</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReqId;
