import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { contractListPageIndexState } from "recoil/atoms";
import useAxiosAuthFunction from "hooks/useAxiosAuth";
import ContractInfo from "components/popup/contractInfo";

const ContractListPage = () => {
  const { apiUrls, axiosFetchA } = useAxiosAuthFunction();
  const [contractList, setContractList] = useState([]);
  const [contractResult, setContractResult] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [selectedContract, setSelectedContract] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [contractListPage, setContractListPage] = useRecoilState(
    contractListPageIndexState
  );

  const openModal = (e, item) => {
    e.preventDefault();
    setSelectedContract(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getContractList = (reqData) => {
    axiosFetchA({
      method: "post",
      url: apiUrls.getContractList,
      requestConfig: { currentNavPage: reqData },
    })
      .then((res) => {
        if (res.success) {
          setContractResult(res.result);
          setContractList(res.contractList);
          setPageList(res.pageList);
          setContractListPage(reqData);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getContractList : ", err);
      });
  };

  const formHandleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    getContractList(contractListPage);
  }, []);

  return (
    <>
      <div className="board-list-content">
        <div className="board-head">
          <div className="board-search">
            <form onSubmit={formHandleSubmit}>
              <fieldset className="">
                <label className="search-keyword">
                  <input
                    type="text"
                    id="searchData"
                    placeholder="검색어를 입력해주세요"
                    name="searchData"
                  />
                </label>
                <button
                  id="btn-search"
                  type="button"
                  className="btn btn-search"
                >
                  검색
                </button>
              </fieldset>
            </form>
          </div>
        </div>

        <div className="board-body board-table" id="idTable">
          <div className="blc-head">
            <ul className="blc-tr">
              <li className="w10">
                <strong>고객사</strong>
              </li>
              <li className="w25">
                <strong>주소</strong>
              </li>
              <li className="w5">
                <strong>담당자</strong>
              </li>
              <li className="w15">
                <strong>연락처</strong>
              </li>
              <li className="w10">
                <strong>협력사</strong>
              </li>
              <li className="w10">
                <strong>현장팀</strong>
              </li>
              <li className="w15">
                <strong>연락처</strong>
              </li>
              <li className="w10">
                <strong>계약상세</strong>
              </li>
            </ul>
          </div>
          <div className="blc-body">
            <ul>
              {contractList?.map((item) => (
                <li key={item.user_id}>
                  <ul className="blc-tr" data-id="<%= item.customer_id %>">
                    <li className="ad_m w10">{item.company_name}</li>
                    <li className="ad_m w25">{item.company_addr1}</li>
                    <li className="ad_m w5">{item.user_name}</li>
                    <li className="ad_m w15">{item.user_tel}</li>
                    <li className="ad_m w10">{item.partner_company_name}</li>
                    <li className="ad_m w10">{item.partner_name}</li>
                    <li className="ad_m w15">{item.partner_tel}</li>
                    <li className="ad_m w10">
                      <button
                        id="btn-search"
                        type="button"
                        className="sellerBtn"
                        onClick={(e) => {
                          openModal(e, item);
                        }}
                      >
                        <img src="/assets/images/admin/icon_more.png" alt="" />
                      </button>
                    </li>
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="board-footer">
          <div className="blc-pagenation">
            <Link
              onClick={() =>
                getContractList(
                  contractResult.currentPage - 10 < 1
                    ? 1
                    : contractResult.currentPage - 10
                )
              }
              className="blc-first icon-page-first"
            >
              <span className="skip">처음</span>
            </Link>
            <Link
              className="blc-prev icon-page-prev"
              onClick={() =>
                getContractList(
                  contractResult.currentPage - 10 < 1
                    ? 1
                    : contractResult.currentPage - 10
                )
              }
            >
              <span className="skip">이전</span>
            </Link>
            <ul className="blc-pages">
              {pageList?.map((item) => (
                <li
                  key={item}
                  className={
                    item === contractResult.currentPage ? "current" : null
                  }
                >
                  <Link onClick={() => getContractList(item)}>{item}</Link>
                </li>
              ))}
            </ul>
            <Link
              className="blc-next icon-page-next"
              onClick={() =>
                getContractList(
                  pageList[pageList.length - 1] + 1 < contractResult.maxPage
                    ? pageList[pageList.length - 1] + 1
                    : pageList[pageList.length - 1]
                )
              }
            >
              <span className="skip">다음</span>
            </Link>
            <Link
              className="blc-last icon-page-last"
              onClick={() =>
                getContractList(
                  pageList[pageList.length - 1] + 1 < contractResult.maxPage
                    ? pageList[pageList.length - 1] + 1
                    : pageList[pageList.length - 1]
                )
              }
            >
              <span className="skip">맨끝</span>
            </Link>
          </div>
        </div>
      </div>
      {modalOpen ? (
        <ContractInfo closeModal={closeModal} userInfo={selectedContract} />
      ) : null}
    </>
  );
};

export default ContractListPage;
