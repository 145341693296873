import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";

const MainPage = () => {
  return (
    <div className="main container">
      <section className="visual-top main video">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>
              클리니션은 Clean과 TechNICIAN의 합성어로
              <br />
              전문적인 클리닝을 제공하는 목표를 갖고 있습니다
            </p>
            <h2>CLEANICIAN</h2>
            <div className="button-area">
              <button type="button" className="button-outline white">
                <Link to="/corevalue">
                  <span>READ MORE+ </span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="video">
          <video poster="../assets/images/top_bg_main.jpg" autoPlay loop muted>
            <source
              src="../assets/images/top_video_main.webm"
              type="video/webm"
            />
            <source
              src="../assets/images/top_video_main.mp4"
              type="video/mp4"
            />
          </video>
        </div>
      </section>
      <section className="main-section-consulting">
        <div className="inner">
          <div className="text-area">
            <h2>공간컨설팅</h2>
            <div className="ment">현황 분석 보고</div>
            <p>클리니션은 업게 최초로 공간컨설팅 결과를 고객에게 전달합니다.</p>
            <p>
              정량적/정성적인 분석으로 고객의 환경을 다각도로 분석을 진행합니다.
              고객님이 직접 요청하는 공간뿐만 아니라 현장 전문가의 분석으로
              인지하지 못했던 세밀한 부분까지 찾아내고 오염도를 체크합니다.
              지금까지 담당자분들께서 직접 확인하고 시간 투자가 필요한 기본
              작업을 이젠 클리니션이 전문가의 시각으로 대신 진행해드립니다.
            </p>
            <p>우리 회사 공간의 현황 분석을 받아보세요</p>
            <button type="button" className="button-outline gray">
              <Link to="/corevalue">
                <span>READ MORE+ </span>
              </Link>
            </button>
          </div>
          <div className="consulting-slider">
            <Swiper
              slidesPerView={2}
              spaceBetween={0}
              navigation
              // autoplay={{ delay: 2000, disableOnInteraction: false }}
              centeredSlides={true}
              modules={[Autoplay, Navigation]}
            >
              <SwiperSlide className="box swiper-slide">
                <div>
                  <img src="../assets/images/main/consulting01.png" alt="" />
                </div>
                <span>STEP 01</span>
                <h3>정량적 수치분석</h3>
                <p>
                  고객현장을 수치적으로 접근하며 <br />
                  분석 카테고리를 기준으로 복합적으로 평가합니다.
                </p>
              </SwiperSlide>
              <SwiperSlide className="box swiper-slide">
                <div>
                  <img src="../assets/images/main/consulting02.png" alt="" />
                </div>
                <span>STEP 02</span>
                <h3>전문가의 정성적 분석</h3>
                <p>
                  다양한 노하우를 갖고 있는 현장 매니저가
                  <br />
                  공간별로 오염도를 체크하여 종합적 판단을 내립니다.
                </p>
              </SwiperSlide>
              <SwiperSlide className="box swiper-slide">
                <div>
                  <img src="../assets/images/main/consulting03.png" alt="" />
                </div>
                <span>STEP 03</span>
                <h3>고객의 요구사항</h3>
                <p>
                  현재 근무하고 계시는 담당자 분의 <br />
                  요구 사항을 정확하게 반영합니다.
                </p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <section className="main-section-service">
        <div className="inner wide">
          <div className="img-area">
            <img src="../assets/images/main/service_bg.png" alt="" />
          </div>
          <div className="text-area">
            <h2>서비스 디자인</h2>
            <div className="ment">
              최적화 서비스 디자인으로 효율적인 비용구조를 실현합니다.
            </div>
            <p>
              클리니션의 클리닝 서비스는 현장 중심의 공간컨설팅으로 시작됩니다.
            </p>
            <p>
              분석된 결과는 고객별 맞춤 서비스를 디자인하기 위한 기초 자료로
              사용되고, 공간별 특성과 오염도를 기준으로 서비스 방안이 만들어
              집니다. 공간별로 취합된 서비스 방안은 고객 전체의 서비스로 최종
              디자인이 완료됩니다. 이것은 현장 분석으로 시작되어 공간별 서비스
              방안 구축 후에 최적의 서비스 디자인으로 완성되는 업계 최초의
              클리닝 서비스 도입 프로세스입니다.
            </p>
            <p>
              단순 견적 비교에서 벗어나 고객별 최적의 서비스 디자인은 클리닝
              비용의 최적화로 이어지고 효율적인 비용 구조를 실현합니다.
            </p>
            <button type="button" className="button-outline black">
              <Link to="/corevalue">
                <span>READ MORE+ </span>
              </Link>
            </button>
          </div>
        </div>
      </section>
      <section className="main-section-system">
        <div className="inner">
          <div className="text-area">
            <h2>서비스 운영시스템(ZIM)</h2>
            <div className="subment">시스템 기반의 체계적인 운영관리</div>
            <p>
              우리가 지불한 비용으로 서비스가 제대로 진행되고 있는지 확인 되어야
              합니다.
              <br />
              실시간으로 현재 우리회사의 서비스 현황을 확인할 수 있어 편리하게
              관리 할 수 있습니다.
            </p>
          </div>
          <ul className="system-list">
            <li>
              <div className="system-list-img">
                <img src="../assets/images/main/system01.png" alt="" />
              </div>
              <div className="system-list-box">
                <h3>현장확인시스템</h3>
                <p>
                  현장의 담당 서비스팀이 서비스 이행 내용을 <br />
                  직접 체크해서 DB화 시킵니다
                </p>
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/main/system02.png" alt="" />
              </div>
              <div className="system-list-box">
                <h3>실시간 관리 시스템</h3>
                <p>
                  고객은 시스템 접속을 통해서 서비스 내용을 편리하게 확인할 수
                  있으며, 수치화 된 서비스 품질을 효율적으로 관리 가능합니다.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="main-section-provide">
        <div className="inner">
          <div className="provide-img">
            <img src="../assets/images/main/report.png" alt="" />
          </div>
          <div className="provide-box">
            <div className="ment1">고객별 전담매니저를 지정합니다.</div>
            <div className="ment2">
              고객별 전담매니저 운영시스템을 기반으로 담당 매니저가 직접
              모니터링하여 서비스 수준을 관리합니다. 비효율적인 서비스에 대한
              품질 평가에서 벗어나 새로운 형태의 서비스 운영관리를 제공합니다.
              고객님은 이제 더 이상 청소 완료된 현장을 돌아다니며 확인하지 않고,
              시스템으로 확인하고 요청사항을 담당 매니저와 협의를 진행합니다.
              일별/월별/분기별로 운영리포트를 제공합니다. 고객의 서비스 품질을
              스스로 평가하여 보고하고 개선하여 지속적인 서비스 품질관리를
              만들어냅니다.
              <br />
              보다 효율적인 서비스 운영관리로 품질은 높이고 관리를 위한 시간은
              절약해드립니다.
            </div>
          </div>
        </div>
      </section>
      <section className="main-partner-system">
        <div className="inner">
          <div className="text-area">
            <h2>클리니션과 함께합니다.</h2>
            <div className="subment"></div>
            <p></p>
          </div>
          <ul className="system-list">
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/금천오랑.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/영등포오랑.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/wordmark.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img
                  src="../assets/images/partner/서울대 캠퍼스타운.png"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/브런치빈.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/몽키우드.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/스토랑트.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/스타벅스.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/바인컨설팅.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/묘동유치원.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/소르아웨딩.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/연세대학교.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img
                  src="../assets/images/partner/서울S가정의학과.png"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/연세미소온치과.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img
                  src="../assets/images/partner/연세으뜸이비인후과.png"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/모쿠슈라.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/강강술래.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/우림FMG.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/코팬글로벌.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img
                  src="../assets/images/partner/여우공간필라테스.png"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/카페하모니.png" alt="" />
              </div>
            </li>

            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/팡뮤제.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/홍대도깨비클럽.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/노블스위밍랩.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/제로백PC.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img
                  src="../assets/images/partner/제이디디지털스튜디오.png"
                  alt=""
                />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/문화산업.png" alt="" />
              </div>
            </li>
            <li>
              <div className="system-list-img">
                <img src="../assets/images/partner/창영기계.png" alt="" />
              </div>
            </li>

            <li>
              <div className="system-list-img">
                <img
                  src="../assets/images/partner/한구구우주산업기술진흥원.png"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default MainPage;
