import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { userInfoState } from "../recoil/userStore";
import { apiUrls } from "../data/apiUrl";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useAxiosFunction from "../hooks/useAxios";

const useAxiosAuth = () => {
  const { urls, axiosFetch } = useAxiosFunction();
  const axiosInstance = axios.create();
  const baseURL =
    process.env.NODE_ENV === "development"
      ? "http://localhost:8915/api"
      : `/api`;
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const navigate = useNavigate();

  const aToken = userInfo.aToken;

  const authFail = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      user_id: null,
      user_name: null,
      user_type: null,
    });

    localStorage.removeItem("rToken");
    navigate("/");
  };

  const authSuccess = (data) => {
    setUserInfo({
      isLogin: true,
      aToken: data.access_token,
      user_id: data.user_id,
      user_name: data.user_name,
      user_type: data.user_type,
    });
  };

  // Rquest
  axiosInstance.interceptors.request.use(
    (config) => {
      config.baseURL = baseURL;
      config.headers["Content-Type"] = "application/json; charset=utf-8";
      config.headers["Accpet"] = "*/*";
      config.headers["Authorization"] = `Bearer ${aToken}`;

      return config;
    },
    (error) => {
      //console.log("interceptors.request err  >", JSON.stringify(error));
      return Promise.reject(error);
    }
  );

  // Response
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      const rToken = localStorage.getItem("rToken");
      if (error.response.status === 401 && !originalRequest._retry) {
        if (rToken) {
          await axiosFetch({
            method: "POST",
            url: urls.rTokenCheck,
            requestConfig: { refresh_token: rToken },
          })
            .then((res) => {
              authSuccess(res);
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${res.access_token}`;
            })
            .catch((err) => {
              authFail();
            });

          axios.defaults.headers = originalRequest.headers;
          return axios.post(
            originalRequest.baseURL + "/" + originalRequest.url,
            null,
            {
              headers: {
                Authorization: originalRequest.headers["Authorization"],
              },
            }
          );
        } else {
          authFail();
        }
      }

      Promise.reject(error);
    }
  );

  return axiosInstance;
};

const useAxiosAuthFunction = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); //different!
  const [controller, setController] = useState();
  const axiosInstance = useAxiosAuth();
  const urls = apiUrls;

  const axiosFetchA = async (configObj) => {
    const { method, url, requestConfig = {} } = configObj;

    try {
      setLoading(true);
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosInstance[method.toLowerCase()](url, {
        ...requestConfig,
        signal: ctrl.signal,
      });
      setResponse(res.data);
      return Promise.resolve(res.data);
    } catch (err) {
      setError(err.message);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return { response, error, loading, urls, apiUrls, axiosFetchA };
};

export default useAxiosAuthFunction;
