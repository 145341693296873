import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { isUserState } from "recoil/atoms";
import { userInfoState } from "recoil/userStore";
import useAxios from "hooks/useAxios";
import DashboardPage from "./zim/dashboardPage";
import CheckListPage from "./zim/checkListPage";
import MonthReportPage from "./zim/monthReportPage";
import ContractDetailPage from "./zim/contractDetailPage";
import WriteCheckList from "./zim/writeCheckList";
import Select from "react-select";
import { Link } from "react-router-dom";
import "assets/css/zim.css";

const ZimPage = () => {
  const [menuIndex, setMenuIndex] = useRecoilState(isUserState);
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch } = useAxios();
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState();
  const [selectedUserId, setSelectedUserId] = useState();

  const ChangeMenu = (e, select) => {
    e.preventDefault();
    setMenuIndex(select);
  };

  const selectedCustomerHandler = (data) => {
    setSelectedCustomerIndex(data.value);
    setSelectedUserId(data.user_id);
  };

  const getCustomerList = () => {
    if (userInfo?.user_type === "A") {
      axiosFetch({
        method: "post",
        url: apiUrls.getCustomerList,
      })
        .then((res) => {
          if (res.success) {
            res.customerList.map((data, index) => {
              data.value = index;
              data.label = data.company_name;
            });
            setCustomerList(res.customerList);
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {
          console.log("[ERROR] getCustomerList  : ", err);
        });
    } else {
      setSelectedUserId(userInfo?.user_id);
    }
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  useEffect(() => {}, [selectedCustomerIndex]);

  return (
    <>
      <div className="container-one">
        {userInfo?.user_type === "A" ? (
          <Select
            className="zim-select adi-company-category"
            options={customerList}
            value={customerList.filter(function (option) {
              return option.value === selectedCustomerIndex;
            })}
            onChange={(e) => selectedCustomerHandler(e)}
            styles={{ zIndex: "99" }}
          ></Select>
        ) : null}
        <div className="container-system">
          <div className="lnb">
            <nav style={{ width: "auto" }}>
              <ul>
                {userInfo.user_type === "C" ? (
                  <>
                    <li
                      className={
                        menuIndex === 0
                          ? "lnb-item lnb-item01 active"
                          : "lnb-item lnb-item01 "
                      }
                      onClick={(e) => ChangeMenu(e, 0)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_01.svg"
                            alt=""
                          />
                        </i>
                        서비스 현황
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 1
                          ? "lnb-item lnb-item02 active"
                          : "lnb-item lnb-item02 "
                      }
                      onClick={(e) => ChangeMenu(e, 1)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_02.svg"
                            alt=""
                          />
                        </i>
                        체크리스트
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 2
                          ? "lnb-item lnb-item03 active"
                          : "lnb-item lnb-item03 "
                      }
                      onClick={(e) => ChangeMenu(e, 2)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_03.svg"
                            alt=""
                          />
                        </i>
                        월간리포트
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 3
                          ? "lnb-item lnb-item04 active"
                          : "lnb-item lnb-item04 "
                      }
                      onClick={(e) => ChangeMenu(e, 3)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_04.svg"
                            alt=""
                          />
                        </i>
                        계약내용
                      </Link>
                    </li>
                  </>
                ) : userInfo.user_type === "A" ? (
                  <>
                    <li
                      className={
                        menuIndex === 0
                          ? "lnb-item lnb-item01 active"
                          : "lnb-item lnb-item01 "
                      }
                      onClick={(e) => ChangeMenu(e, 0)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_01.svg"
                            alt=""
                          />
                        </i>
                        서비스 현황
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 1
                          ? "lnb-item lnb-item02 active"
                          : "lnb-item lnb-item02 "
                      }
                      onClick={(e) => ChangeMenu(e, 1)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_02.svg"
                            alt=""
                          />
                        </i>
                        체크리스트
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 2
                          ? "lnb-item lnb-item03 active"
                          : "lnb-item lnb-item03 "
                      }
                      onClick={(e) => ChangeMenu(e, 2)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_03.svg"
                            alt=""
                          />
                        </i>
                        월간리포트
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 3
                          ? "lnb-item lnb-item04 active"
                          : "lnb-item lnb-item04 "
                      }
                      onClick={(e) => ChangeMenu(e, 3)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_04.svg"
                            alt=""
                          />
                        </i>
                        계약내용
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 4
                          ? "lnb-item lnb-item05 active"
                          : "lnb-item lnb-item05 "
                      }
                      onClick={(e) => ChangeMenu(e, 4)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_02.svg"
                            alt=""
                          />
                        </i>
                        체크작성
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      className={
                        menuIndex === 1
                          ? "lnb-item lnb-item02 active"
                          : "lnb-item lnb-item02 "
                      }
                      onClick={(e) => ChangeMenu(e, 1)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_02.svg"
                            alt=""
                          />
                        </i>
                        체크리스트
                      </Link>
                    </li>
                    <li
                      className={
                        menuIndex === 4
                          ? "lnb-item lnb-item05 active"
                          : "lnb-item lnb-item05 "
                      }
                      onClick={(e) => ChangeMenu(e, 4)}
                    >
                      <Link>
                        <i>
                          <img
                            src="../assets/images/common/icon_lnb_02.svg"
                            alt=""
                          />
                        </i>
                        체크리스트 작성
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
          {menuIndex === 0 ? (
            <DashboardPage user_id={selectedUserId} />
          ) : menuIndex === 1 ? (
            <CheckListPage user_id={selectedUserId} />
          ) : menuIndex === 2 ? (
            <MonthReportPage user_id={selectedUserId} />
          ) : menuIndex === 3 ? (
            <ContractDetailPage user_id={selectedUserId} />
          ) : menuIndex === 4 ? (
            <WriteCheckList />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ZimPage;
