import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfoState } from "recoil/userStore";

const Header = () => {
  const [mobileBtn, setMobileBtn] = useState(false);
  const [foldBtn, setFoldBtn] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const navigate = useNavigate();

  const goConsulting = () => {
    navigate("/reqconsulting");
  };

  const goAdmin = () => {
    navigate("/admin");
  };

  const mobileMenuBtn = () => {
    setMobileBtn(!mobileBtn);
  };

  const mobileFoldBtn = () => {
    setFoldBtn(!foldBtn);
  };

  const logout = () => {
    setUserInfo({
      aToken: null,
      isLogin: false,
      user_id: null,
      user_name: null,
      user_type: null,
    });
    localStorage.removeItem("rToken");
    setMobileBtn(!mobileBtn);
    navigate("/");
  };

  return (
    <>
      <header className="header">
        <div className="inner">
          <Link to="/">
            <h1 className="header-logo" />
          </Link>
          <button type="button" className="btn-header" onClick={mobileMenuBtn}>
            <span>메뉴 버튼</span>
          </button>
          <div id="gnd" className="gnb">
            <nav className="top-nav">
              <ul className="navi-list">
                <li className="navi-item">
                  <Link to="/corevalue" className="btn-navi depth1">
                    <span>핵심가치</span>
                  </Link>
                </li>
                <li className="navi-item">
                  <Link to="/regularclinic" className="btn-navi depth1">
                    <span>정기클리닝</span>
                  </Link>
                </li>
                <li className="navi-item">
                  <Link to="/irregularclinic" className="btn-navi depth1">
                    <span>비정기클리닝</span>
                  </Link>
                </li>
                <li className="navi-item">
                  <Link to="/specialclinic" className="btn-navi depth1">
                    <span>특수클리닝</span>
                  </Link>
                </li>
                <li className="navi-item">
                  <Link to="/business" className="btn-navi depth1">
                    <span>현장갤러리</span>
                  </Link>
                </li>
                {userInfo?.isLogin === true ? (
                  <li className="navi-item">
                    <Link to="zim" className="btn-navi depth1">
                      <span>ZIM</span>
                    </Link>
                  </li>
                ) : null}
              </ul>
            </nav>
            <nav className="utility-nav">
              <ul>
                <li>
                  {userInfo?.isLogin === true ? (
                    <button
                      type="button"
                      className="button-primary border-radius"
                      onClick={logout}
                    >
                      <span>로그아웃</span>
                    </button>
                  ) : (
                    <Link to="/login" className="">
                      <span>로그인/회원가입</span>
                    </Link>
                  )}
                </li>
                <li>
                  <button
                    type="button"
                    className="button-primary border-radius"
                    onClick={goConsulting}
                  >
                    <span>무료견적요청</span>
                  </button>
                </li>
                <li>
                  {userInfo.user_type === "A" ? (
                    <button
                      type="button"
                      className="button-primary border-radius"
                      onClick={goAdmin}
                    >
                      <span>관리자</span>
                    </button>
                  ) : null}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <div className={mobileBtn ? "mobile-header active" : "mobile-header"}>
        <div className="mobile-header-inner">
          <div className="mobile-header-top">
            <h1 className="header-logo">
              <Link to="/" onClick={mobileMenuBtn}>
                <span>Cleanician</span>
              </Link>
            </h1>
            <button type="button" className="btn-close" onClick={mobileMenuBtn}>
              <span>메뉴 버튼</span>
            </button>
          </div>
          <nav id="mobile_gnb" className="mobile-gnb">
            <ul className="navi-list">
              <li className="navi-item">
                <Link
                  to="/corevalue"
                  className="btn-navi depth1"
                  onClick={mobileMenuBtn}
                >
                  <span>핵심가치</span>
                </Link>
              </li>
              <li className="navi-item">
                <Link
                  to="/regularclinic"
                  className="btn-navi depth1"
                  onClick={mobileMenuBtn}
                >
                  <span>정기클리닝</span>
                </Link>
              </li>
              <li className="navi-item">
                <Link
                  to="/irregularclinic"
                  className="btn-navi depth1"
                  onClick={mobileMenuBtn}
                >
                  <span>비정기클리닝</span>
                </Link>
              </li>
              <li className="navi-item">
                <Link
                  to="/specialclinic"
                  className="btn-navi depth1"
                  onClick={mobileMenuBtn}
                >
                  <span>특수클리닝</span>
                </Link>
              </li>
              <li className="navi-item">
                <Link
                  to="/business"
                  className="btn-navi depth1"
                  onClick={mobileMenuBtn}
                >
                  <span>현장갤러리</span>
                </Link>
              </li>
              {userInfo?.isLogin === true ? (
                <li className="navi-item">
                  <Link
                    to="zim"
                    className="btn-navi depth1"
                    onClick={mobileMenuBtn}
                  >
                    <span>ZIM</span>
                  </Link>
                </li>
              ) : null}
              {/* <li
                className={
                  foldBtn ? "navi-item has-sub active" : "navi-item has-sub"
                }
              >
                <button className="btn-navi depth1" onClick={mobileFoldBtn}>
                  <span>청소서비스</span>
                </button>
                <ul className="navi-sub-list">
                  <li className="navi-sub-item">
                    <Link
                      to="/regularclinic"
                      className="btn-navi depth2"
                      onClick={mobileMenuBtn}
                    >
                      <span>정기클리닝</span>
                    </Link>
                  </li>
                  <li className="navi-sub-item">
                    <Link
                      to="/irregularclinic"
                      className="btn-navi depth2"
                      onClick={mobileMenuBtn}
                    >
                      <span>비정기클리닝</span>
                    </Link>
                  </li>
                  <li className="navi-sub-item">
                    <Link
                      to="/specialclinic"
                      className="btn-navi depth2"
                      onClick={mobileMenuBtn}
                    >
                      <span>특수클리닝</span>
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="navi-item">
                <Link
                  to="/reqconsulting"
                  className="btn-navi depth1"
                  onClick={mobileMenuBtn}
                >
                  <span>무료견적요청</span>
                </Link>
              </li>
            </ul>
            <ul className="mobile-utility">
              {userInfo.isLogin !== true ? (
                <li>
                  <Link
                    to="/login"
                    className="button-primary size-l"
                    onClick={mobileMenuBtn}
                  >
                    <span>로그인</span>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link className="button-primary size-l" onClick={logout}>
                    <span>로그아웃</span>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          <p className="mobile-navi-copy">
            CopyrightⒸ2023.클리니션 All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Header;
