import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const isUserState = atom({
  key: "isUserState",
  default: true,
  effects_UNSTABLE: [persistAtom],
});

const userListPageIndexState = atom({
  key: "userListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const customerListPageIndexState = atom({
  key: "customerListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const subcontractListPageIndexState = atom({
  key: "subcontractListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const contractListPageIndexState = atom({
  key: "contractListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const checkListPageIndexState = atom({
  key: "checkListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

const feedbackListPageIndexState = atom({
  key: "feedbackListPageIndexState",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export {
  isUserState,
  userListPageIndexState,
  customerListPageIndexState,
  subcontractListPageIndexState,
  contractListPageIndexState,
  checkListPageIndexState,
  feedbackListPageIndexState,
};
