import React from "react";

const SpecialClinicPage = () => {
  return (
    <div className="container">
      <section className="visual-top cleaning3">
        <div className="inner">
          <div className="box">
            <span></span>
            <p>
              클리니션은 종합 청소서비스 제공을 목표로 차별화된 서비스를
              제공합니다
            </p>
            <h2>특수클리닝</h2>
          </div>
        </div>
      </section>
      <section className="cleaning-section-special">
        <div className="inner">
          <div className="text-area">
            <h2>특수클리닝</h2>
            <div className="ment">
              클리니션은 고객의 니즈에 충족하는 모든 서비스를 제공합니다.
            </div>
          </div>
          <section className="cleaning-special-list">
            <div>
              <ul>
                <li className="process1">
                  <div className="process-img-box">
                    <img src="../assets/images/cleaning/special01.jpg" alt="" />
                  </div>
                  <div className="process-text-box">
                    <i>
                      <img
                        src="../assets/images/cleaning/icon_special01.png"
                        alt=""
                      />
                    </i>
                    <h3>화재청소</h3>
                    <p>
                      화재로 인하여 손상된 공간은 전문가의 손길로 새롭게
                      태어나게 됩니다. 손상된 공간의 상태에 맞춤 전문인력과
                      전용약품을 제공합니다.
                    </p>
                  </div>
                </li>
                <li className="process2">
                  <div className="process-img-box">
                    <img src="../assets/images/cleaning/special02.jpg" alt="" />
                  </div>
                  <div className="process-text-box">
                    <i>
                      <img
                        src="../assets/images/cleaning/icon_special02.png"
                        alt=""
                      />
                    </i>
                    <h3>쓰레기집 청소</h3>
                    <p>
                      더렵혀지고 오염물로 가득해진 공간은 고민하지 마시고
                      클리니션에 연락주세요. 청결한 공간으로 돌아갈 수 있습니다.
                    </p>
                  </div>
                </li>
                <li className="process3">
                  <div className="process-img-box">
                    <img src="../assets/images/cleaning/special03.jpg" alt="" />
                  </div>
                  <div className="process-text-box">
                    <i>
                      <img
                        src="../assets/images/cleaning/icon_special03.png"
                        alt=""
                      />
                    </i>
                    <h3>침수청소</h3>
                    <p>
                      폭우나 홍수로 침수된 공간은 전문업체가 아니면 처리하기
                      어렵습니다. 완벽한 복원을 경험할 수 있습니다.
                    </p>
                  </div>
                </li>
                <li className="process4">
                  <div className="process-img-box">
                    <img src="../assets/images/cleaning/special04.jpg" alt="" />
                  </div>
                  <div className="process-text-box">
                    <i>
                      <img
                        src="../assets/images/cleaning/icon_special04.png"
                        alt=""
                      />
                    </i>
                    <h3>폐기물처리</h3>
                    <p>
                      더럽고 처리하기 힘든 다양한 폐기물의 처리를 맡겨주세요.
                      <br />
                      어떤 종류의 폐기물도 처리 가능합니다.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default SpecialClinicPage;
