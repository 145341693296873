import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { userInfoState } from "recoil/userStore";
import useAxios from "hooks/useAxios";

const ContractDetailPage = (props) => {
  const userID = props.user_id;
  const userInfo = useRecoilValue(userInfoState);
  const { apiUrls, axiosFetch } = useAxios();
  const [contractInfo, setContractInfo] = useState();
  const [physicals, setPhysicals] = useState();
  const [services, setServices] = useState();
  const [days, setDays] = useState([
    { name: "월", checked: false },
    { name: "화", checked: false },
    { name: "수", checked: false },
    { name: "목", checked: false },
    { name: "금", checked: false },
    { name: "토", checked: false },
    { name: "일", checked: false },
  ]);

  const weekCheckboxHandler = (data, index) => {
    setDays((days) =>
      days?.map((day, currentIndex) =>
        currentIndex === index ? { ...day, checked: data } : day
      )
    );
  };

  const getContractInfo = () => {
    axiosFetch({
      method: "post",
      url: apiUrls.getContractInfoU,
      requestConfig: {
        user_id: userID,
      },
    })
      .then((res) => {
        if (res.success) {
          setContractInfo(res.contractInfo);

          res.contractInfo?.contract_days?.split(",")?.map((data, index) => {
            if (data === "1") weekCheckboxHandler(true, index);
          });

          let physicalArray =
            res.contractInfo?.contract_physical_name?.split(",");
          setPhysicals(() =>
            physicalArray?.map((physical) => ({
              name: physical,
              checked: true,
            }))
          );
          let serviceArray =
            res.contractInfo?.contract_service_name?.split(",");
          setServices(() =>
            serviceArray?.map((service) => ({
              name: service,
              checked: true,
            }))
          );
        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        console.log("[ERROR] getContractInfoU : ", err);
      });
  };

  useEffect(() => {
    if (userID !== undefined) {
      getContractInfo();
    }
  }, []);

  useEffect(() => {
    if (userID !== undefined) {
      getContractInfo();
    }
  }, [userID]);

  return (
    <div className="content">
      <div className="title">
        <div>
          <h1>ZIM(Zone Interactive Manager)</h1>
          <p>
            클리니션의 ZIM은 고객서비스관리 강화, 업무생산성 향상, 운영안정성,
            추가 제안 제시를 달성합니다.
          </p>
        </div>
      </div>
      <div className="form-area-zim">
        <div className="form-item-zim company">
          <label className="form-title">회사명</label>
          <div className="form-content input-button">
            {contractInfo?.company_name}
          </div>
        </div>

        <div className="form-item-zim form-complex range">
          <div className="form-complex-item">
            <label className="form-title">사업장종류</label>
            <div className="form-content input-button">
              {contractInfo?.contract_place}
            </div>
          </div>
          <div className="form-complex-item">
            <label className="form-title">방문요일</label>
            <div className="form-content">
              <ul className="check-item">
                {days?.map((item, index) => (
                  <li>
                    <div className="input-check-zim">
                      <input
                        key={item.name}
                        type="checkbox"
                        id={`inpd${index}`}
                        checked={item.checked}
                        readOnly={true}
                      />
                      <label htmlFor={`inpd${index}`}>{item.name}</label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="form-item-zim form-complex">
          <div className="form-complex-item">
            <label className="form-title">담당매니저</label>
            <div className="form-content input-button">
              {contractInfo?.manager_name}
            </div>
          </div>
          <div className="form-complex-item">
            <label className="form-title">담당매니저 연락처</label>
            <div className="form-content input-button">
              {contractInfo?.manager_tel}
            </div>
          </div>
        </div>

        <div className="form-item-zim range">
          <div className="form-title">물리적 범위</div>
          <div className="form-content">
            <ul className="check-item">
              {physicals?.map((item, index) => (
                <li>
                  <div className="input-check-zim">
                    <input
                      key={item.name}
                      type="checkbox"
                      id={`inpp0${index}`}
                      checked={item.checked}
                      readOnly={true}
                    />
                    <label for={`inpp0${index}`}>{item.name}</label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="form-item-zim range">
          <label className="form-title">상세 서비스 항목</label>
          <div className="form-content">
            <ul className="check-item">
              {services?.map((item, index) => (
                <li>
                  <div className="input-check-zim">
                    <input
                      key={item.name}
                      type="checkbox"
                      id={`inps0${index}`}
                      checked={item.checked}
                      readOnly={true}
                    />
                    <label for={`inps0${index}`}>{item.name}</label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="form-item-zim">
          <label className="form-title">
            기타사항
            <br />
            (구두서술필요부분)
          </label>
          <div className="form-content">
            <textarea
              className="textarea"
              defaultValue={contractInfo?.contract_comment}
              readOnly={true}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractDetailPage;
